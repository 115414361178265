import { PaymentMethod } from "@stripe/stripe-js";
import axios from "axios";
import { RUNWAY_TOKEN } from "utils/constants";

const getHeaders = () => ({
  "x-access-token": localStorage.getItem(RUNWAY_TOKEN) || "",
});

const getHeaderForGenerateAIResult = () => ({
  "x-access-token": localStorage.getItem(RUNWAY_TOKEN) || "",
  "Content-Type": "multipart/form-data",
  Authorization: `Bearer ${localStorage.getItem(RUNWAY_TOKEN)}`,
});

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 480000, // 30000,
});

const Exception = (message: any) => {
  const error: any = new Error(message);

  error.success = false;

  return error;
};

const processError = (error: any) => {
  if (error?.response?.status === 401) {
    localStorage.removeItem(RUNWAY_TOKEN);
    window.location.href = "/";
  }

  if (error?.response?.data) {
    // client received an error response (5xx, 4xx)
    return error.response.data;

    // throw Exception(error.response.data?.message);
  }

  if (error?.request) {
    // client never received a response, or request never left
    throw Exception("It's not you, it's us, want to give it another try?");
  }

  // anything else
  throw Exception("Oops! Something went wrong.");
};

// ---------- USERS ----------

export const signup = async (payload: {
  accountType: string;
  firstName: string;
  lastName?: string;
  email: string;
  password: string;
  confirmPassword: string;
  phone: string;
  companyName?: string;
  atv?: { isATV: boolean | null; optedIn: boolean | null };
}) => {
  try {
    const response = await API.post("/users/signup", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const signupMember = async (payload: {
  firstName: string;
  lastName?: string;
  email: string;
  password: string;
  confirmPassword: string;
  phone: string;
  business: string;
}) => {
  try {
    const response = await API.post("/users/signup/member", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getMembers = async () => {
  try {
    const response = await API.get("/users/members", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getDashboardData = async () => {
  try {
    const response = await API.get("/users/dashboard", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const signin = async (payload: { email: string; password: string }) => {
  try {
    const response = await API.post("/users/signin", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const socialSignin = async (payload: {
  token: string;
  provider: string;
}) => {
  try {
    const response = await API.post("/users/social-login", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const verifyEmail = async (params: { token: string }) => {
  try {
    const response = await API.get("/users/verify-email", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getCurrentUser = async () => {
  try {
    const response = await API.get("/users/me", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const sendPasswordResetInstructions = async (payload: {
  email: string;
}) => {
  try {
    const response = await API.post(
      "/users/send-password-reset-instructions",
      payload,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const verfyPasswordResetLinkExpiry = async (payload: {
  token: string;
}) => {
  try {
    const response = await API.post(
      "/users/verify-reset-password-link-expiry",
      payload,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const resetPassword = async (payload: {
  email: string;
  newPassword: string;
  confirmNewPassword: string;
}) => {
  try {
    const response = await API.post("/users/reset-password", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getStats = async () => {
  try {
    const response = await API.get("/users/stats", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const updateProfile = async (payload: any) => {
  try {
    const response = await API.put("/users/profile", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getStudentDetails = async (params: { id: string }) => {
  try {
    const response = await API.get("/users/student", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const addPersonalityTest = async (payload: {
  type: string;
  results: any;
}) => {
  try {
    const response = await API.put("/users/personality", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getStudentProfileStats = async (params: { id: string }) => {
  try {
    const response = await API.get("/users/student/profile-stats", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getSignedUrlOfS3 = async (key: string) => {
  // "aws/generate-signed-url"
  try {
    const response = await API.get("/attachments/generate-signed-url", {
      params: { key },
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getSignedUrlOfResume = async (
  key: string,
  bucket: string = ""
) => {
  try {
    const response = await API.get(
      "/attachments/generate-signed-url-of-resume",
      {
        params: bucket !== "" ? { key, bucket } : { key },
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getAIAssessmentPdf = async () => {
  try {
    const response = await API.get("/users/ai-assessment-pdf", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const inviteMember = async (payload: { email: string }) => {
  try {
    const response = await API.post("/users/invite/member", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const toggleATVPremium = async (payload: {
  boolState: boolean;
  userId: string;
}) => {
  try {
    const response = await API.post("/users/togglePremium", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- PROJECTS ----------

export const listProjects = async (params: {
  business: string;
  student: string;
  status?: Array<string>;
  types?: Array<string>;
  search?: string;
  explore?: string;
}) => {
  try {
    const response = await API.get("/projects", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const listProjectsCareerFair = async (params: {
  types?: Array<string>;
  search?: string;
}) => {
  try {
    const response = await API.get("/projects/career-fair", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getProjectDetails = async (id: string) => {
  try {
    const response = await API.get("/projects/details", {
      params: { id },
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getSkills = async (payload: any) => {
  try {
    const response = await API.post("/ai/get-skills", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const generateQuestions = async (payload: any) => {
  try {
    const response = await API.post("/ai/generate-questions", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const generateAIResult = async (payload: any) => {
  try {
    const response = await API.post(
      `${process.env.REACT_APP_PYTHON_BASE_URL}/vetting/generate-feedback/`,
      payload,
      {
        headers: getHeaderForGenerateAIResult(),
      }
    );
    if (response.status === 200 && response.data) {
      return true;
    }
    return false;
    // return response.data;
  } catch (error) {
    return false;
  }
};

export const createProject = async (payload: any) => {
  try {
    const response = await API.post("/projects", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const updateProject = async (payload: any) => {
  try {
    const response = await API.put("/projects", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const saveProjectAsDraft = async (payload: any) => {
  try {
    const response = await API.post("/projects/save-as-draft", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const updateStudentProject = async (payload: any) => {
  try {
    const response = await API.put("/projects/student", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const addBusinessInterviewTimes = async (payload: any) => {
  try {
    const response = await API.post(
      "/projects/business-add-interview-times",
      payload,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const requestToInterviewProject = async (payload: {
  id: string;
  studentId: string;
}) => {
  try {
    const response = await API.post("/projects/request-to-interview", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const startProject = async (payload: {
  id: string;
  students: Array<string>;
}) => {
  try {
    const response = await API.post("/projects/start", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const finishProject = async (payload: { id: string }) => {
  try {
    const response = await API.post("/projects/finish", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const deleteProject = async (params: { id: string }) => {
  try {
    const response = await API.delete("/projects", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const reviewProject = async (payload: {
  id: string;
  studentId: string;
  rating: number;
  review: string;
}) => {
  try {
    const response = await API.post("/projects/review", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getTimesheets = async (params: {
  id: string;
  weekStart?: any;
}) => {
  try {
    const response = await API.get("/projects/timesheets", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const createTimesheets = async (payload: any) => {
  try {
    const response = await API.post("/projects/timesheets", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const acceptInterview = async (payload: any) => {
  try {
    const response = await API.post("/projects/accept-interview", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- ADMIN ----------

export const listBusiness = async () => {
  try {
    const response = await API.get("/super-admin/business", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const signInAsBusiness = async (payload: { id: string }) => {
  try {
    const response = await API.post("/super-admin/signin-business", payload, {
      headers: getHeaders(),
    });

    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- PAYMENTS ----------

export const createSetupIntent = async () => {
  try {
    const response = await API.post(
      "/payments/setup-intent",
      {},
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const listPaymentMethods = async () => {
  try {
    const response = await API.get("/payments/payment-methods", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error: any) {
    return processError(error);
  }
};

export const addPaymentMethod = async (payload: {
  id: string | PaymentMethod;
}) => {
  try {
    const response = await API.post("/payments/payment-methods", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getExpressConnectedAccountLoginLink = async () => {
  try {
    const response = await API.get("/payments/account/login-link", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getConnectedAccount = async () => {
  try {
    const response = await API.get("/payments/account", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getConnectedAccountRefreshLink = async () => {
  try {
    const response = await API.get("/payments/account/link", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const createConnectedAccount = async () => {
  try {
    const response = await API.post(
      "/payments/account",
      {},
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const listInvoices = async (params: { id: string }) => {
  try {
    const response = await API.get("/payments/invoice", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const payInvoice = async (payload: {}) => {
  try {
    const response = await API.post("/payments/invoice", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getPaymentIntent = async (params: { id: string }) => {
  try {
    const response = await API.get("/payments/intent", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error: any) {
    return processError(error);
  }
};

// PROMO CODES

export const applyPromoCode = async (payload: { promoCode: string }) => {
  try {
    const response = await API.post("/payments/promo-code", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error: any) {
    return processError(error);
  }
};

// meeting link

export const getMeetingLink = async (payload: {
  projectId: string;
  studentId: string;
}) => {
  try {
    const response = await API.post("/projects/get-meetings", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error: any) {
    return processError(error);
  }
};

export const createExternalLink = async (payload: {
  link: any;
  project_id: any;
  student_id: any;
  meeting_date: any;
  meeting_time: any;
  interwe_type: any;
  interwe_id?: any;
}) => {
  try {
    const response = await API.post("/projects/create-meeting", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error: any) {
    return processError(error);
  }
};

export const declineInterviewSlot = async (payload: {
  projectId: string;
  time: string;
  day: string;
}) => {
  try {
    const response = await API.post("/projects/declined-interview", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error: any) {
    return processError(error);
  }
};

export const getDeclineSlots = async (params: { projectId: string }) => {
  try {
    const response = await API.get("/projects/get-declined-interview", {
      params,
      headers: getHeaders(),
    });

    return response.data;
  } catch (error: any) {
    return processError(error);
  }
};

export const matchingStudents = async (payload: { projectId: string }) => {
  try {
    const response = await API.post("/projects/matching-students", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error: any) {
    return processError(error);
  }
};

export const sendVerificationEmail = async (email: string) => {
  try {
    const response = await API.post("/users/resend-verification-email", {
      email,
      headers: getHeaders(),
    });

    return response.data;
  } catch (error: any) {
    return processError(error);
  }
};
