import { listPaymentMethods } from "api";
import AddNewCardDialog from "components/AddNewCardDialog";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Button } from "runway-kit/Button";

interface BusinessPaymentDetailsProps {
  user: any;
}

export const BusinessPaymentDetails: React.FC<BusinessPaymentDetailsProps> = ({
  user,
}) => {
  const [isCCModalOpen, setIsCCModalOpen] = useState(false);
  const [cards, setCards] = useState([]);

  const fetchPaymentMethods = async () => {
    try {
      const response = await listPaymentMethods();
      setCards(response?.data?.cards);
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  const onCloseCCModal = async (shouldFetch: boolean) => {
    setIsCCModalOpen(false);
    if (shouldFetch) {
      await fetchPaymentMethods();
    }
  };

  return (
    <>
      <div className="bg-white p-4 border border-gray-200 rounded-2xl w-full flex flex-col gap-3">
        <div className="flex justify-between items-center">
          <p className="fw-bold text-gray-800 fs-5">Payment</p>
          {!cards?.length && (
            <Button
              type="button"
              className="btn btn_small bg_secondary"
              onClick={() => setIsCCModalOpen(true)}
              size="sm"
            >
              Add New Card
            </Button>
          )}
        </div>
        {cards?.length ? (
          <div className="flex flex-col">
            <div className="border-b border-gray-300 flex justify-between pb-1 mb-1">
              <p>Card Number</p>
              <p>Expiration Date</p>
            </div>
            {cards.map((card: any) => (
              <div className="flex justify-between gap-1">
                <p>***{card?.lastFourDigits}</p>
                <p>
                  {card?.expiryMonth}/{card?.expiryYear}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-sm flex justify-center items-center text-gray-400 font-semibold flex-col flex-grow">
            No Payment Methods Added
          </div>
        )}
      </div>
      {isCCModalOpen && (
        <AddNewCardDialog
          user={user}
          open={isCCModalOpen}
          onClose={onCloseCCModal}
        />
      )}
    </>
  );
};
