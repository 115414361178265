import {
  useFormContext,
  FieldError,
  get,
  ValidationRule,
} from "react-hook-form";

interface FormTextInputProps {
  name: string;
  label?: string;
  placeholder?: string;
  type?: string;
  isRequired?: boolean;
  validation?: {
    minLength?: ValidationRule<number>;
    maxLength?: ValidationRule<number>;
    min?: ValidationRule<number>;
    max?: ValidationRule<number>;
    pattern?: ValidationRule<RegExp>;
  };
  formOptions?: any; // https://react-hook-form.com/api/useform/register
  disabled?: boolean;
  autoFocus?: boolean;
}

export const FormTextInput: React.FC<FormTextInputProps> = ({
  name,
  label,
  placeholder,
  type,
  isRequired = true,
  validation = {},
  formOptions = {},
  disabled = false,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const error: FieldError = get(errors, name);

  const registerFields = register(name, {
    required: isRequired,
    ...validation,
    ...formOptions,
  });

  return (
    <div className="flex flex-col gap-3 w-full">
      {label && (
        <div className="flex items-center gap-1">
          <label htmlFor={name} className="text-sm">
            {label}
          </label>
        </div>
      )}
      <div className="flex flex-col gap-1">
        <input
          id={name}
          placeholder={placeholder}
          type={type}
          className="input_style"
          {...registerFields}
          disabled={disabled}
        />
        {error && (
          <div className="flex flex-col text-xs text-red-600">
            {!!error.types &&
              Object.values(error.types).map((message) => (
                <p key={message as string}>{message}</p>
              ))}
            {error.type === "required" && "Required"}
          </div>
        )}
      </div>
    </div>
  );
};
