export interface SpinnerProps {
  variant?: "primary" | "secondary";
  size?: "small" | "medium" | "large";
}

export const Spinner: React.FC<SpinnerProps> = ({
  variant = "secondary",
  size: sizeProp = "medium",
}) => {
  let size;
  if (sizeProp === "small") {
    size = "w-4 h-4";
  } else if (sizeProp === "medium") {
    size = "w-5 h-5";
  } else {
    size = "w-6 h-6";
  }

  let foregroundColor = "border-l-gray-500";
  let trackColor = "border-gray-200";
  if (variant === "primary") {
    foregroundColor = "border-l-gray-200";
    trackColor = "border-green-400";
  }

  return (
    <div
      role="progressbar"
      aria-live="polite"
      aria-label="Loading..."
      className={`${size} rounded-full border-[2px] ${trackColor} ${foregroundColor} animate-spin`}
    />
  );
};
