import { useContext, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { updateProfile } from "api";
import { wrapNameTwoLetters } from "utils/common";
import { BusinessProfilePane } from "./ProfilePane/BusinessProfilePane";
import { BusinessSettingsPane } from "./SettingsPane/BusinessSettingsPane";
import { Button } from "runway-kit/Button";
import { Tabs } from "runway-kit/Tabs";
import { BusinessSettingsContext } from "./BusinessSettingsContext";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

const BUSINESS_PROFILE_TABS = {
  profile: "profile" as const,
  settings: "settings" as const,
};

interface BusinessProfileSettingsProps {
  user: any;
  manuBarExpend: boolean;
  setUser: any;
  manuBarToogle: () => void;
  profileImageUrl: string;
  goToSettings?: boolean;
}

type Inputs = {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  companyName: string;
};

export const BusinessProfileSettings: React.FC<
  BusinessProfileSettingsProps
> = ({ user, setUser, profileImageUrl, goToSettings = false }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [activeTab, setActiveTab] = useState<
    keyof typeof BUSINESS_PROFILE_TABS
  >(BUSINESS_PROFILE_TABS.profile);
  const { isEditing, setIsEditing, companyLogoBase64, setCompanyLogoBase64 } =
    useContext(BusinessSettingsContext);
  const [isSavingProfile, setIsSavingProfile] = useState(false);

  const methods = useForm<Inputs>({
    values: {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
      companyName: user.business.name,
    },
  });

  const { handleSubmit, reset, clearErrors } = methods;

  useEffect(() => {
    if (goToSettings) {
      setActiveTab(BUSINESS_PROFILE_TABS.settings);
    }
  }, [goToSettings]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setIsSavingProfile(true);

      const response = await updateProfile({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        companyName: data.companyName,
        companyLogoBase64,
      });

      if (response?.success) {
        const userInfo = response?.data?.user;
        userInfo.business = { name: data.companyName };
        setUser(userInfo);
        enqueueSnackbar(response?.message, { variant: "success" });
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    } finally {
      setIsSavingProfile(false);
      setIsEditing(false);
    }
  };

  const profileLetters = (
    <div className="profile_image" style={{ width: 44, height: 44 }}>
      <p className="profileText" style={{ fontSize: 18 }}>
        {wrapNameTwoLetters(user?.firstName, user?.lastName)}
      </p>
    </div>
  );

  const onCancelEdit = () => {
    setIsEditing(false);
    clearErrors();
    reset();
    setCompanyLogoBase64(user.companyLogo);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col w-full flex-grow">
          <header className="flex flex-col sm:flex-row p-3 border-b border-gray-200 justify-between items-center bg-white gap-3">
            <div className="flex gap-3 items-center w-full">
              {profileImageUrl?.length > 0 ? (
                <div
                  className="w-[2.75rem] h-[2.75rem] bg-cover bg-center rounded"
                  style={{ backgroundImage: `url(${profileImageUrl})` }}
                />
              ) : (
                profileLetters
              )}
              <div className="flex flex-col">
                <p className="text-lg font-bold text-gray-900 leading-[1.5rem]">
                  {user?.firstName ?? ""} {user?.lastName ?? ""}
                </p>
                <p className="text-sm font-medium">
                  {user?.business?.name ?? ""}
                </p>
              </div>
            </div>
            <div className="flex gap-3 w-full sm:w-[initial]">
              <Button
                size="sm"
                disabled={!isEditing}
                type="submit"
                variant={isEditing ? "secondary" : "primary"}
                isLoading={isSavingProfile}
                className="w-full sm:w-[initial]"
              >
                Save
              </Button>
              {isEditing ? (
                <Button
                  onClick={onCancelEdit}
                  size="sm"
                  variant="danger"
                  className="w-full sm:w-[initial]"
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  size="sm"
                  onClick={() => setIsEditing(true)}
                  className="w-full whitespace-nowrap"
                >
                  Edit Profile
                </Button>
              )}
            </div>
          </header>
          <div className="flex flex-col flex-grow bg-rw-page-body p-3 pt-1">
            <Tabs
              tabs={[
                {
                  key: BUSINESS_PROFILE_TABS.profile,
                  label: "Profile",
                  panel: (
                    <BusinessProfilePane profileImageUrl={profileImageUrl} />
                  ),
                },
                {
                  key: BUSINESS_PROFILE_TABS.settings,
                  label: "Settings",
                  panel: <BusinessSettingsPane user={user} />,
                },
              ]}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
