import React, { useEffect } from "react";
import ProfileBar from "components/Profile/ProfileBar";
import ProfileDetails from "components/Profile/ProfileDetails";
import { useSnackbar } from "notistack";
import { useMergeState } from "utils/custom-hooks";
import {
  getStudentProfileStats,
  addBusinessInterviewTimes,
  getMeetingLink,
  createExternalLink,
  getSignedUrlOfResume,
} from "api";
import SelectInterviewWindow from "./SelectInterviewWindow";
import ChangeInterviewWindow from "./ChangeInterviewWindow";
import CreateInterviewWindow from "./CreateInterviewWindow";
import InterviewTimesBox from "./InterviewTimesBox";
import InterviewLink from "components/MyProjects/RequestInterviewProject/InterviewLink";
import { ContentCopy } from "@mui/icons-material";

type Props = {
  user: any;
  projectId: string;
  studentStatus: string;
  interviewTimes: any;
};

const StudentProfile = ({
  user,
  projectId,
  studentStatus,
  interviewTimes,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const tempTime = [
    {
      name: "Interview Times 1",
      monday: {
        enabled: true,
        times: [540, 1020],
      },
      tuesday: {
        enabled: true,
        times: [540, 1020],
      },
      wednesday: {
        enabled: true,
        times: [540, 1020],
      },
      thursday: {
        enabled: true,
        times: [540, 1020],
      },
      friday: {
        enabled: true,
        times: [540, 1020],
      },
      saturday: {
        enabled: false,
        times: [540, 1020],
      },
      sunday: {
        enabled: false,
        times: [540, 1020],
      },
    },
  ];
  const tempTime1 = [
    {
      name: "Interview Times 1",
      monday: {
        enabled: true,
        times: [540, 1020],
      },
      tuesday: {
        enabled: true,
        times: [540, 1020],
      },
      wednesday: {
        enabled: true,
        times: [540, 1020],
      },
      thursday: {
        enabled: true,
        times: [540, 1020],
      },
      friday: {
        enabled: true,
        times: [540, 1020],
      },
      saturday: {
        enabled: false,
        times: [540, 1020],
      },
      sunday: {
        enabled: false,
        times: [540, 1020],
      },
    },
  ];
  const [state, setState] = useMergeState({
    interviewWindow: [],
    newInterviewWindow: [...tempTime],
    selectedWindow: -1,
    windowName: "",

    avatar: "",
    avatarFile: null,
    avatarBase64: null,

    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    university: "",
    resume: {},
    resumeFile: null,
    resumeBase64: null,
    website: "",
    bio: "",
    keySkills: "",
    skills: [],
    profileTypes: [],
    showProfile: true,

    notificationSettings: {
      email: {
        newProjectPosted: false,
        requestedToInterview: false,
        newProjectStarted: false,
        timesheetReminders: false,
      },
      sms: {
        newProjectPosted: false,
        requestedToInterview: false,
        newProjectStarted: false,
        timesheetReminders: false,
      },
    },
    personalityTests: {},

    // profile stats
    reviews: [],
    stats: {
      projects: {
        finished: 0,
      },
      reviews: {
        total: 0,
        overallRating: 0,
      },
    },
    payment: {
      payoutsEnabled: false,
      chargesEnabled: false,
      detailsSubmitted: false,
    },

    isEditable: false,

    errors: {},
    renderState: 0,
    interviewLink: "",
    interviewDate: "",
    interviewTime: "",
    interviewType: 0,
    externalInterviewLink: "",
    isLoading: false,
  });

  console.log(user?.email);

  const addInterviewWindow = (slots: []) => {
    const temp = state.newInterviewWindow;
    temp.push(slots);
    setState({ newInterviewWindow: [...temp] });
  };
  const init = async () => {
    try {
      let initialState: any = {};

      //   const id = isBusinessView ? studentId : user?._id;

      const res = await getMeetingLink({ projectId, studentId: user._id });

      const profileStatsResponse = await getStudentProfileStats({
        id: user._id,
      });

      initialState = {
        reviews: profileStatsResponse?.data?.reviews,
        stats: profileStatsResponse?.data?.stats,
      };

      //   if (isBusinessView && studentId) {
      //     const response = await getStudentDetails({ id: studentId });
      //     const data = response?.data;
      //     // if (data && data?.resume?.key) {
      //     //   const signedUrlRes = await getSignedUrlOfResume(data?.resume?.key);
      //     //   if (signedUrlRes?.success) {
      //     //     data.resume = {
      //     //       ...data.resume,
      //     //       url: signedUrlRes.data.url,
      //     //     };
      //     //   }
      //     // }
      //     initialState = {
      //       ...initialState,
      //       avatar: data?.avatar,
      //       profileTypes: data?.profileTypes,
      //       firstName: data?.firstName,
      //       lastName: data?.lastName,
      //       email: data?.email,
      //       phone: data?.phone,
      //       university: data?.university,
      //       resume: data?.resume,
      //       website: data?.website,
      //       bio: data?.bio,
      //       keySkills: Array(data?.skills)?.join(),
      //       skills: data?.skills,
      //       personalityTests: data?.personality,
      //       notificationSettings: data?.notificationSettings,
      //       javaCertified: data?.javaCertified,
      //     };
      //   } else {
      //     const connectAccountDetailsResponse = await getConnectedAccount();
      //     // if (user && user?.resume?.key) {
      //     //   const signedUrlRes = await getSignedUrlOfResume(user?.resume?.key);
      //     //   if (signedUrlRes?.success) {
      //     //     user.resume = {
      //     //       ...user.resume,
      //     //       url: signedUrlRes.data.url,
      //     //     };
      //     //   }
      //     // }

      const resumeUrl = await getSignedUrlOfResume(user?.resume?.key);
      initialState = {
        ...initialState,
        avatar: user?.avatar,
        profileTypes: user?.profileTypes,
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        phone: user?.phone,
        university: user?.university,
        resume: {
          ...user.resume,
          url: resumeUrl.data.url,
        },
        website: user?.website,
        bio: user?.bio,
        keySkills: Array(user?.skills)?.join(),
        skills: user?.skills,
        personalityTests: user?.personality,
        notificationSettings: user?.notificationSettings,
        javaCertified: user?.javaCertified,
      };

      // if (connectAccountDetailsResponse?.data) {
      //   initialState = {
      //     ...initialState,
      //     payment: connectAccountDetailsResponse.data,
      //   };
      // }
      //   }

      setState(initialState);
      if (res.success) {
        setState({
          interviewLink: res.data[0].link,
          interviewDate: res.data[0].date,
          interviewTime: res.data[0].time,
          interviewType: res.data[0].interviewType === "external" ? 1 : 0,
        });
      }
    } catch (error: any) {
      //   enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  useEffect(() => {
    init();
  }, []);

  const handleSubmit = async () => {
    try {
      if (state.selectedWindow !== -1) {
        setState({ isLoading: true });
        const response = await addBusinessInterviewTimes({
          days: state?.interviewWindow[state.selectedWindow],
          projectId,
          studentId: user._id,
        });
        if (response.success) {
          enqueueSnackbar("Requested Submitted.", { variant: "success" });
          window.location.reload();
        }
      } else {
        enqueueSnackbar("select a interview window.", { variant: "error" });
      }

      //   setState({ confirmType: "business", shouldShowConfirmation: true });
      // } else {
      //   setState({ shouldShowConfirmTimes: true });
      // }
    } finally {
      setState({ isLoading: false });
    }
  };

  const handleCalendarLink = async () => {
    try {
      if (state.externalInterviewLink.length > 0) {
        setState({ isLoading: true });
        const response = await createExternalLink({
          link: state.externalInterviewLink,
          project_id: projectId,
          student_id: user._id,
          meeting_date: Date.now(),
          meeting_time: Date.now(),
          interwe_type: "External",
        });
        if (response.success) {
          enqueueSnackbar("calendar link submited", { variant: "success" });
          window.location.reload();
        }
      } else {
        enqueueSnackbar("Please enter calendar link.", { variant: "error" });
      }
    } finally {
      setState({ isLoading: false });
    }
  };

  const goTONextRenderState = (value: number = 1) => {
    setState({ renderState: state.renderState + value });
  };

  const buttonTittle = () => {
    let title = { title: "", function: () => {}, goBackFunction: () => {} };

    if (state.renderState === 0) {
      title = {
        title: "Request to Interview",
        function: () => goTONextRenderState(),
        goBackFunction: () => {},
      };
    }
    if (state.renderState === 1) {
      title = {
        title: "Submit Interview Times",
        function: () => (state.isLoading === false ? handleSubmit() : {}),
        goBackFunction: () => setState({ renderState: 0 }),
      };
    }
    if (state.renderState === 2) {
      title = {
        title: "Continue",
        function: () => {
          const temp = state.interviewWindow;
          temp.push(state.newInterviewWindow[0]);
          setState({
            interviewWindow: temp,
            selectedWindow: temp.length - 1,
          });
          goTONextRenderState();
        },
        goBackFunction: () => setState({ renderState: 1 }),
      };
    }
    if (state.renderState === 3) {
      title = {
        title: "Submit Interview Times",
        function: () => (state.isLoading === false ? handleSubmit() : {}),
        goBackFunction: () => {
          setState({ renderState: 2, interviewWindow: [], selectedWindow: -1 });
        },
      };
    }
    if (state.renderState === 4) {
      title = {
        title: "Submit Calendar",
        function: () => handleCalendarLink(),
        goBackFunction: () => setState({ renderState: 1 }),
      };
    }
    return title;
  };

  useEffect(() => {
    const temp = state?.interviewWindow;
    if (temp.length > 0) {
      temp[temp.length - 1].name = state.windowName;
      setState({ interviewWindow: [...temp] });
    }
  }, [state.windowName]);

  return (
    <div>
      <ProfileBar
        firstName={state?.firstName}
        lastName={state?.lastName}
        university={state?.university}
        disablePrimaryButton={
          studentStatus === "REQUESTED_TO_INTERVIEW" || state.isLoading === true
        }
        buttonTittle={
          studentStatus === "REQUESTED_TO_INTERVIEW"
            ? "Requested to Interview"
            : buttonTittle().title
        }
        handlePress={() =>
          studentStatus === "REQUESTED_TO_INTERVIEW"
            ? () => {}
            : buttonTittle().function()
        }
        showBackButton={state.renderState > 0}
        backPress={() => buttonTittle().goBackFunction()}
        // backPress={() => setState({ renderState: 0 })}
      />
      {/* <ProfileDetails /> */}
      {studentStatus === "REQUESTED_TO_INTERVIEW" && (
        <div className="bg_light p-3 p-md-4 w-100 overflow-hidden">
          <div className="p-3 p-md-4 bg_white justify-content-center border border_gray border_r_16px">
            <p className="fs-4 fw-bold text_primary mb-1">
              Requested to Interview
            </p>
            <p className="mb-4 text_primary">
              {state?.interviewLink?.length === 0
                ? "Your candidate has been notified with your interview time windows. You will be notified once an interview time is scheduled."
                : "Your interview has been scheduled. You have been emailed confirmation. Use the link below to join."}
            </p>
            {state?.interviewLink?.length === 0 && (
              <InterviewTimesBox
                data={interviewTimes?.curr}
                showCheckBox={false}
              />
            )}
            {state?.interviewLink?.length !== 0 && (
              <InterviewLink
                type={state?.interviewType}
                link={state?.interviewLink}
                date={state?.interviewDate}
                time={state?.interviewTime}
              />
            )}
          </div>
        </div>
      )}
      {state.renderState === 0 && (
        <ProfileDetails
          user={user}
          isBusinessView={false}
          isEditable={false}
          state={state}
          personalityTestState={() => {}}
          showPremiumFeatures={() => {}}
          resumeRef={null}
          togglePremiumFeatures={() => {}}
          handleStart={() => {}}
          handleRedirectWebsite={() => {}}
          togglePersonalityInfographicPopup={() => {}}
          closePersonalityInfographicPopup={() => {}}
          handleChange={() => {}}
          handleResumeRef={() => {}}
          handleRemoveResume={() => {}}
          handleResumeChange={() => {}}
          handleChangeBio={() => {}}
          handleChangeKeySkills={() => {}}
          handleRemoveSkills={() => {}}
          handleChangeProfileType={() => {}}
          showAiAssessmentButton={false}
        />
      )}
      {state.renderState === 1 && (
        <SelectInterviewWindow
          interviewWindow={state.interviewWindow}
          goTONextRenderState={goTONextRenderState}
          setInterviewWindow={(index: number) =>
            setState({ selectedWindow: index })
          }
        />
      )}
      {state.renderState === 2 && (
        <ChangeInterviewWindow
          interviewWindow={state.newInterviewWindow}
          updateNewWindow={(slot: any) =>
            setState({ newInterviewWindow: [...slot] })
          }
        />
      )}

      {state.renderState === 3 && (
        <CreateInterviewWindow
          interviewTimes={state.newInterviewWindow}
          setWindowName={(e: any) =>
            setState({ windowName: e.currentTarget.value })
          }
        />
      )}
      {state.renderState === 4 && (
        <div className="p-0 px-md-3 mt-3">
          <div className="bg-white w-100 border border_gray border_r_16px border_sm_r_0 mt-2 mb-4 mb-md-0 p-4">
            <p className="fs-4 fw-bold text_primary mb-4">
              External Calendar Link
            </p>
            <label className="mb-3">Enter Link</label>
            <input
              type="text"
              value={state.externalInterviewLink}
              onChange={(e) =>
                setState({ externalInterviewLink: e.currentTarget.value })
              }
              className="form-control input_style mb-3 w-50 w_sm_100"
              placeholder="Calendar URL"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentProfile;
