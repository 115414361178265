import ErrorMessage from "components/ErrorMessage";

type Props = {
  type: "text" | "password" | "number" | "textarea" | "date" | "tel";
  label?: string;
  name?: string;
  placeholder?: string;
  defaultValue?: any;
  value: any;
  className?: string;
  rows?: number;
  error?: string;
  readOnly?: boolean;
  disabled?: boolean;
  onChange?: any;
  onWheel?: any;
  onInput?: any;
  isMultipleCol?: boolean;
};

export default function TextField(props: Props) {
  const {
    type = "text",
    name,
    placeholder,
    label,
    defaultValue,
    value,
    className = "",
    rows,
    error = "",
    readOnly = false,
    disabled = false,
    onChange,
    onWheel,
    onInput,
    isMultipleCol = false,
  } = props;

  return (
    <>
      {label && (
        <label
          className={`text-sm font-medium ${error ? "text-rw-red-dark" : ""}`}
        >
          {label}
        </label>
      )}

      {type === "textarea" ? (
        <div className="flex flex-col h-40">
          <textarea
            id={name}
            name={name}
            rows={rows}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            readOnly={readOnly}
            className={`border-[1px] border-[#D7DBE1] rounded-md px-4 py-2 w-full placeholder:text-xs placeholder:text-[#00000040] ${
              label ? "mt-1" : ""
            } ${error ? "border-rw-red" : ""} ${className}`}
          />

          {error && <ErrorMessage message={error} />}
        </div>
      ) : isMultipleCol ? (
        <>
          <input
            id={name}
            type={type}
            name={name}
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={value}
            readOnly={readOnly}
            disabled={disabled}
            onChange={onChange}
            onWheel={onWheel}
            onInput={onInput}
            // className={`border-[1px] border-[#D7DBE1] rounded-md h-12 px-4 w-full placeholder:text-xs placeholder:text-[#00000040] placeholder:font-medium ${
            //   label ? "mt-1" : ""
            // } ${error ? "border-rw-red" : ""} ${className}`}
            className={`input_style ${label ? "mt-1" : ""} ${
              error ? "border-rw-red mb-0" : "mb-3"
            } ${className}`}
            // className={`form-control input_style mb-3 h-12 ${
            //   label ? "mt-1" : ""
            // } ${error ? "border-rw-red" : ""} ${className}`}
          />

          {error && <ErrorMessage message={error} />}
        </>
      ) : (
        <div className="flex flex-col w-100">
          <input
            id={name}
            type={type}
            name={name}
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={value}
            readOnly={readOnly}
            disabled={disabled}
            onChange={onChange}
            onWheel={onWheel}
            onInput={onInput}
            // className={`border-[1px] border-[#D7DBE1] rounded-md h-12 px-4 w-full placeholder:text-xs placeholder:text-[#00000040] placeholder:font-medium ${
            //   label ? "mt-1" : ""
            // } ${error ? "border-rw-red" : ""} ${className}`}
            className={`input_style ${label ? "mt-1" : ""} ${
              error ? "border-rw-red mb-0" : "mb-3"
            } ${className}`}
            // className={`form-control input_style mb-3 h-12 ${
            //   label ? "mt-1" : ""
            // } ${error ? "border-rw-red" : ""} ${className}`}
          />

          {error && <ErrorMessage message={error} />}
        </div>
      )}
    </>
  );
}
