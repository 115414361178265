import React, { useEffect } from "react";
import { useMergeState } from "utils/custom-hooks";

const slotTime = [
  {
    date: "Monday, April 15",
    slots: [
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
      "5:00 PM",
      "5:30 PM",
      "6:00 PM",
      "6:30 PM",
    ],
  },
  {
    date: "Tuesday, April 16",
    slots: [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
      "5:00 PM",
      "5:30 PM",
      "6:00 PM",
      "6:30 PM",
    ],
  },
];

const tempSlot = [
  "9:00 AM",
  "9:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 PM",
  "12:30 PM",
  "1:00 PM",
  "1:30 PM",
  "2:00 PM",
  "2:30 PM",
  "3:00 PM",
  "3:30 PM",
  "4:00 PM",
  "4:30 PM",
  "5:00 PM",
  "5:30 PM",
  "6:00 PM",
  "6:30 PM",
];

const hourInMinutes = [
  "9:00",
  "9:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
];

const timeAmPm = [
  "9:00 AM",
  "9:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 PM",
  "12:30 PM",
  "1:00 PM",
  "1:30 PM",
  "2:00 PM",
  "2:30 PM",
  "3:00 PM",
  "3:30 PM",
  "4:00 PM",
  "4:30 PM",
  "5:00 PM",
  "6:30 PM",
  "7:00 PM",
  "7:30 PM",
];

const daysIndex = [1, 2, 3, 4, 5, 6, 0];

type Props = {
  slots: any;
  updateSelectedWindow: (day: any, time: any, slotIndex: any) => void;
  declineSlot: any;
  setDisableButtons: () => void;
};

const SelectTimeSlot = ({
  slots,
  updateSelectedWindow,
  declineSlot,
  setDisableButtons,
}: Props) => {
  const [state, setState] = useMergeState({
    selectedDay: -1,
    selectedSlot: -1,
    slotTime: [...slotTime],
    declineSlots: [[], [], [], [], [], [], []],
  });

  useEffect(() => {
    setState({ declineSlots: [...declineSlot] });
  }, [declineSlot]);

  const updateTimeSlotState = (
    dayIndex: number,
    slotIndex: number,
    time: string
  ) => {
    setState({ selectedDay: dayIndex, selectedSlot: slotIndex });
    updateSelectedWindow(dayIndex, time, timeAmPm[slotIndex]);
  };

  const isActive = (dayIndex: number, slotIndex: number) => {
    let str = "";
    if (dayIndex === state?.selectedDay && slotIndex === state?.selectedSlot) {
      str = "active";
    } else {
      str = "";
    }
    return str;
  };

  const generateIndex = (value: number) => {
    const slots = [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
      "5:00 PM",
    ];
    const newVal = (value / 60 - 9) * 2;
    return newVal;
  };

  useEffect(() => {
    slotTime.length = 0;
    generateIndex(690);
    if (slots?.curr?.monday?.enabled) {
      slotTime.push({
        date: "Monday",
        slots: [
          ...tempSlot.slice(
            generateIndex(slots.curr.monday.times[0]),
            generateIndex(slots.curr.monday.times[1])
          ),
        ],
      });
    }
    if (slots?.curr?.tuesday?.enabled) {
      slotTime.push({
        date: "Tuesday",
        slots: [
          ...tempSlot.slice(
            generateIndex(slots.curr.tuesday.times[0]),
            generateIndex(slots.curr.tuesday.times[1])
          ),
        ],
      });
    }
    if (slots?.curr?.wednesday?.enabled) {
      slotTime.push({
        date: "Wednesday",
        slots: [
          ...tempSlot.slice(
            generateIndex(slots.curr.wednesday.times[0]),
            generateIndex(slots.curr.wednesday.times[1])
          ),
        ],
      });
    }
    if (slots?.curr?.thursday?.enabled) {
      slotTime.push({
        date: "Thursday",
        slots: [
          ...tempSlot.slice(
            generateIndex(slots.curr.thursday.times[0]),
            generateIndex(slots.curr.thursday.times[1])
          ),
        ],
      });
    }
    if (slots?.curr?.friday?.enabled) {
      slotTime.push({
        date: "Friday",
        slots: [
          ...tempSlot.slice(
            generateIndex(slots.curr.friday.times[0]),
            generateIndex(slots.curr.friday.times[1])
          ),
        ],
      });
    }
    if (slots?.curr?.saturday?.enabled) {
      slotTime.push({
        date: "Saturday",
        slots: [
          ...tempSlot.slice(
            generateIndex(slots.curr.saturday.times[0]),
            generateIndex(slots.curr.saturday.times[1])
          ),
        ],
      });
    }
    if (slots?.curr?.sunday?.enabled) {
      slotTime.push({
        date: "Sunday",
        slots: [
          ...tempSlot.slice(
            generateIndex(slots.curr.sunday.times[0]),
            generateIndex(slots.curr.sunday.times[1])
          ),
        ],
      });
    }

    setState({
      slotTime: [...slotTime],
    });
  }, [slots]);

  useEffect(() => {
    let bool = false;

    const check = state?.slotTime.map((day: any, index: any) => {
      return day.slots.map((slot: any, ind: any) =>
        state.declineSlots[index].includes(timeAmPm[ind])
      );
    });

    check.map((da: any) => {
      if (da.includes(false) === false) {
        bool = true;
      } else {
        bool = false;
      }
    });
    if (bool) {
      setDisableButtons();
    }
  }, [state.slotTime, state.declineSlots]);

  return (
    <>
      <p className="text_primary fs-6 pb-4">
        Your interview has been scheduled. You have been emailed confirmation.
        Click below to view details.
      </p>

      {state?.slotTime.map((day: any, index: any) => (
        <div key={index}>
          <p className="fw-bold text_black font_18 mb-3">{day.date}</p>
          <div className="d-flex flex-wrap mb-4">
            {day.slots.map(
              (slot: any, ind: any) =>
                state.declineSlots[index].includes(timeAmPm[ind]) === false && (
                  <div
                    key={ind}
                    onClick={() => updateTimeSlotState(index, ind, slot)}
                    className={`time_box ${isActive(index, ind)}`}
                  >
                    <span>{slot}</span>
                  </div>
                )
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default SelectTimeSlot;
