import React, { useEffect, useState } from "react";
import logo from "../../../assets/imgs/stack.svg";
import moment from "moment";
import CheckIcon from "@mui/icons-material/Check";
import { Link } from "react-router-dom";
import { getSignedUrlOfS3 } from "api";
import { wrapNameTwoLetters } from "utils/common";

const businessColorType = [
  {
    statusBgColor: "rgba(32, 176, 47, 0.05)",
    className: "text_green",
  },
  {
    statusBgColor: "rgba(227, 133, 22, 0.05)",
    className: "text_orange",
  },
  {
    statusBgColor: "rgba(234, 241, 254, 1)",
    className: "text_secondary",
  },
];

const studentColorType = [
  {
    statusBgColor: "rgba(32, 176, 47, 0.05)",
    className: "text_green",
  },
  // {
  //   statusBgColor: "rgba(234, 241, 254, 1)",
  //   className: "text_secondary",
  // },
  {
    statusBgColor: "rgba(227, 133, 22, 0.05)",
    className: "text_orange",
  },
  {
    statusBgColor: "rgba(234, 241, 254, 1)",
    className: "text_secondary",
  },
  {
    statusBgColor: "rgba(234, 241, 254, 1)",
    className: "text_secondary",
  },
  {
    statusBgColor: "rgba(234, 241, 254, 1)",
    className: "text_secondary",
  },
  {
    statusBgColor: "rgba(234, 241, 254, 1)",
    className: "text_secondary",
  },

  {
    statusBgColor: "rgba(210, 44, 22, 0.05)",
    className: "text_redish",
  },
  {
    statusBgColor: "rgba(234, 241, 254, 1)",
    className: "text_secondary",
  },
  {
    statusBgColor: "rgba(210, 44, 22, 0.05)",
    className: "text_redish",
  },
];

const ProjectCard = ({
  projectName,
  description,
  skillList,
  projectStatus,
  hourlyRate,
  isStudent,
  link,
  companyLogo,
  owner,
  updatedAt,
  business,
}: {
  projectName: string;
  description: string;
  skillList: Array<string>;
  projectStatus: number;
  hourlyRate?: string;
  isStudent: boolean;
  link: string;
  owner?: any;
  updatedAt?: string;
  business?: any;
  companyLogo?: any;
}) => {
  const [projectTypes, setProjectTypes] = useState([
    "Ongoing Projects",
    "Requested to Interview",
    "Applied",
    "Listed",
    "Inactive",
    "Draft",
    "Complete",
  ]);
  const [colorType, setColorType] = useState([...studentColorType]);
  // const [projectImage, setProjectImage] = useState(logo);

  // const isCompanyLogo = owner && owner?.companyLogo;
  const isBusinessName = business && business.name;

  // const getSignedUrlOfImage = async (key: string) => {
  //   const signedUrlRes = await getSignedUrlOfS3(key);
  //   if (signedUrlRes?.success) {
  //     setProjectImage(signedUrlRes.data.url);
  //   }
  // };
  // useEffect(() => {
  //   if (isCompanyLogo && owner?.companyLogo?.key) {
  //     getSignedUrlOfImage(owner.companyLogo.key);
  //   }
  // }, []);

  const initials = (
    <div className="profile_image" style={{ width: 50, height: 50 }}>
      <p className="profileText fs-5">
        {wrapNameTwoLetters(
          business?.name.split(" ")[0],
          business?.name.split(" ")[1]
        ).toUpperCase()}
      </p>
    </div>
  );
  return (
    <div>
      <Link to={link}>
        <div className="box_window box-shadow">
          <div
            className="d-flex align-items-center"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {companyLogo ? (
              <div className="lg_stack_logo">
                <img src={companyLogo} />
              </div>
            ) : isBusinessName ? (
              initials
            ) : (
              <div className="lg_stack_logo">
                <img src={logo} />
              </div>
            )}
            <div>
              <p
                className="text_black fw-bold fs-6 ms-3"
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  fontFamily: "system-ui",
                }}
              >
                {projectName}
              </p>
              {isBusinessName && (
                <p className="font_12 text_primary  ms-3">
                  {business?.name || ""}
                </p>
              )}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              className="mt-3"
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "95%",
                height: 30,
                overflow: "hidden",
              }}
            >
              {skillList.map((data, index) => (
                <span key={index} className="tag" style={{ marginRight: 5 }}>
                  {data}
                </span>
              ))}

              {/* <span className="tag">+3</span> */}
            </div>
            {skillList.length > 7 && (
              <span className="tag mt-3">+{skillList.length - 7}</span>
            )}
          </div>
          <hr className="solid" style={{ marginTop: 10, marginBottom: 10 }} />
          <div
            className="d-flex align-items-center justify-content-between"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <p
              className={`font_14 fw_500 ${colorType[projectStatus].className}`}
              style={{
                backgroundColor: colorType[projectStatus].statusBgColor,
                padding: 10,
                borderRadius: 8,
                flexWrap: "wrap",
              }}
            >
              <span className="refresh_icon">
                {projectStatus === 6 ? (
                  <CheckIcon
                    style={{
                      width: 20,
                      height: 20,
                      marginTop: -3,
                      marginRight: 5,
                    }}
                  />
                ) : (
                  <i
                    className={
                      projectStatus === 0 ? "far fa-redo-alt" : "fal fa-clock"
                    }
                    style={{ color: "inherit", marginRight: 10 }}
                    aria-hidden="true"
                  />
                )}
              </span>
              {projectTypes[projectStatus]}{" "}
            </p>
            <p
              className="font_12 fw-bold text_black"
              style={{
                fontSize: 12,
                // fontWeight: "bolder",
                fontWeight: "700",
                fontFamily: "'Inter', 'sans-serif'",
                // fontFamily: "system-ui",
              }}
            >
              {hourlyRate
                ? `$${hourlyRate}`
                : moment(updatedAt).format("DD/MM/YY hh:mm")}
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ProjectCard;
