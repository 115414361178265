import { HTMLAttributes, ReactNode } from "react";
import { Spinner } from "./Spinner";

export interface ButtonProps {
  variant?: "primary" | "secondary" | "outline" | "danger";
  size?: "lg" | "md" | "sm" | "xs";
  disabled?: boolean;
  children?: ReactNode;
  full?: boolean;
  type?: "submit" | "reset" | "button";
  form?: string;
  isLoading?: boolean;
  className?: string;
}

export const Button: React.FC<
  ButtonProps & HTMLAttributes<HTMLButtonElement>
> = ({
  children,
  variant = "primary",
  size = "md",
  disabled = false,
  full,
  type = "button",
  form,
  isLoading,
  onClick,
  className,
  ...rest
}) => {
  const base =
    "flex items-center justify-center rounded-lg font-semibold h-[fit-content]";

  const buttonSize = {
    lg: "py-4 px-8 text-[1rem] leading-[130%]",
    md: "py-3 px-8 text-[.875rem] leading-[100%]",
    sm: "py-2 px-8 text-base leading-[130%]",
    xs: "py-1 px-3 text-[.75rem] leading-[100%]",
  };

  const buttonColor = {
    primary: `
            ${
              disabled
                ? "bg-gray-200"
                : "bg-rw-blue hover:bg-blue-700 active:bg-blue-500"
            } text-white
        `,
    secondary:
      "bg-rw-blue-light hover:bg-blue-200 active:bg-blue-400 text-rw-blue",
    outline: "border border-gray-400",
    danger: "bg-red-700 hover:bg-red-600 active:bg-red-800 text-white",
  };

  const hasDisabledBorder = variant === "secondary" || variant === "outline";

  return (
    <button
      aria-busy={isLoading}
      className={`${base} ${buttonSize[size]} ${buttonColor[variant]} ${
        full ? "w-full" : "w-initial"
      } ${
        disabled
          ? `bg-gray-300 text-white cursor-not-allowed ${
              hasDisabledBorder ? "border border-gray-400" : ""
            }`
          : "cursor-pointer"
      } ${isLoading ? "cursor-not-allowed bg-opacity-75" : ""} ${className}`}
      disabled={disabled}
      type={type}
      form={form}
      onClick={isLoading || disabled ? undefined : onClick}
      {...rest}
    >
      {isLoading && (
        <div className="absolute">
          <Spinner variant={variant === "primary" ? "primary" : undefined} />
        </div>
      )}
      <div className={`${isLoading ? "opacity-0" : "opacity-100"}`}>
        {children}
      </div>
    </button>
  );
};
