import React, { ReactNode, useMemo, useState } from "react";

// create context
export const BusinessSettingsContext = React.createContext({
  isEditing: false,
  setIsEditing: () => {},
} as any);

export const BusinessSettingsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [companyLogoBase64, setCompanyLogoBase64] = useState("");

  const value = useMemo(() => {
    return {
      isEditing,
      setIsEditing,
      companyLogoBase64,
      setCompanyLogoBase64,
    };
  }, [isEditing, setIsEditing, companyLogoBase64, setCompanyLogoBase64]);

  return (
    <BusinessSettingsContext.Provider value={value}>
      {children}
    </BusinessSettingsContext.Provider>
  );
};
