import { ReactNode, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiTabs from "@mui/material/Tabs";
import MuiTab from "@mui/material/Tab";

type Tab = {
  key: string;
  label: string;
  panel: ReactNode;
};

interface TabsProps {
  tabs: Tab[];
  activeTab: string;
  setActiveTab: any;
}

const AntTabs = styled(MuiTabs)({
  borderBottom: "1px solid rgba(0,0,0,0.1)",
  "& .MuiTabs-indicator": {
    backgroundColor: "#0B6FFF",
    height: "3px",
  },
});

const AntTab = styled((props: any) => <MuiTab disableRipple {...props} />)(
  () => ({
    textTransform: "none",
    fontWeight: 600,
    fontSize: "1.25rem",
    color: "#686868",
    "&:hover": {
      color: "rgba(0,0,0,0.4)",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#000000",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);

export const Tabs: React.FC<TabsProps> = ({
  tabs,
  activeTab,
  setActiveTab,
}) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <AntTabs
        value={activeTab}
        onChange={handleChange}
        aria-label="ant example"
      >
        {tabs.map((tab) => (
          <AntTab key={tab.key} value={tab.key} label={tab.label} />
        ))}
      </AntTabs>
      {tabs.find((tab) => tab.key === activeTab)?.panel}
    </>
  );
};
