import { Outlet, Link } from "react-router-dom";
import ExternalNav from "components/Nav/external";
import InternalNav from "components/Nav/internal";
import FooterNav from "components/Nav/footer";
import NewNavBar from "components/Nav/newNavBar";
import { useWindowSize } from "utils/custom-hooks";

type Props = {
  isLoggedIn: boolean;
  user: any;
  onLogout: () => void;
  manuBarExpend: boolean;
  manuBarToogle: () => void;
};

export default function DefaultLayout({
  isLoggedIn,
  user,
  onLogout,
  manuBarExpend,
  manuBarToogle,
}: Props) {
  return (
    <>
      {isLoggedIn ? (
        <NewNavBar
          isLoggedIn={isLoggedIn}
          user={user}
          onLogout={onLogout}
          manuBarExpend={manuBarExpend}
          manuBarToogle={manuBarToogle}
        />
      ) : (
        // <InternalNav isLoggedIn={isLoggedIn} user={user} onLogout={onLogout} />
        // <NewNavBar
        //   isLoggedIn={isLoggedIn}
        //   user={user}
        //   onLogout={onLogout}
        //   manuBarExpend={manuBarExpend}
        // />
        <ExternalNav />
      )}
      {/* <NewNavBar isLoggedIn={isLoggedIn} user={user} onLogout={onLogout} /> */}
      {/* <InternalNav isLoggedIn={isLoggedIn} user={user} onLogout={onLogout} /> */}
      <div
        // className={isLoggedIn ? "w-full min-h-screen sm:h-screen" : ""}
        style={{ paddingBottom: useWindowSize().width < 560 ? 60 : 0 }}
        className="flex flex-col flex-grow"
      >
        <Outlet />
      </div>

      {/* {isLoggedIn && <FooterNav user={user} />} */}
    </>
  );
}
