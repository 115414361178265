import React from "react";
import { wrapFullName } from "utils/common";
import { formatDate, getDayByIndex } from "utils/date";
import { InvoiceStatus } from "utils/constants";
import { useMergeState } from "utils/custom-hooks";

type Props = {
  invoice: any;
  hasPrev: boolean;
  hasNext: boolean;
  currentInvoice: any;
  handlePreviousWeek: () => void;
  handleNextWeek: () => void;
  onCalculatePerHourRate: (timesheet: any) => any;
  invoiceStatus: () => any;
  handlePayInvoice: () => void;
};

const TimeSheetDetails = ({
  invoice,
  hasPrev,
  hasNext,
  currentInvoice,
  handlePreviousWeek,
  handleNextWeek,
  onCalculatePerHourRate,
  invoiceStatus,
  handlePayInvoice,
}: Props) => {
  const processingFees = +Number(
    (currentInvoice?.totalAmount || 0) * 0.03
  ).toFixed(2);
  const [state, setState] = useMergeState({
    isBtnDisable: false,
  });

  return (
    <div>
      {invoice.length === 0 ? null : (
        <>
          <div className="d-flex justify-content-center pt-4">
            <span
              className={`fs-5 ${
                hasPrev ? "text_secondary cursor_pointer" : "text_light_primary"
              }`}
              onClick={() => (hasPrev ? handlePreviousWeek() : null)}
            >
              <i className="fa-regular fa-chevron-left" />
            </span>
            <div className="text-center px-5">
              <p className="font_12">WEEK START</p>
              <p className="font_14 text_secondary fw-bold">
                {formatDate(currentInvoice.weekStart, "ddd MMM Do, YYYY")}
              </p>
            </div>
            <span
              className={`fs-5 ${
                hasNext ? "text_secondary cursor_pointer" : "text_light_primary"
              }`}
              onClick={() => (hasNext ? handleNextWeek() : null)}
            >
              <i className="fa-regular fa-chevron-right" />
            </span>
          </div>
          <div className="border-bottom box_shadow w-100 my-4" />

          <div className="smaller_box">
            {currentInvoice?.timesheets?.map((timesheet: any) => (
              <React.Fragment key={timesheet._id}>
                <div className="total_hours">
                  <p className="font-semibold mb-3 font_18 text-capitalize">
                    {wrapFullName(
                      timesheet?.student?.firstName,
                      timesheet?.student?.lastName
                    )}
                  </p>
                  <table className="table table-borderless">
                    <thead>
                      {/* <tr>
                        <td>
                        <span className="font-semibold">
                          {wrapFullName(
                            timesheet?.student?.firstName,
                            timesheet?.student?.lastName
                          )}
                        </span>
                        </td>
                      </tr> */}
                      <tr>
                        <th scope="col">Total Hours</th>
                        <th scope="col" className="text-end text_secondary">
                          {/* {state?.totalHours} Hours */}
                          {timesheet.totalHours} Hours
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {timesheet?.timesheet.map((elmt: any, index: number) => (
                        <tr key={index}>
                          <td>{getDayByIndex(index)}</td>
                          <td className="text-end">{elmt.hours} Hours</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {timesheet.weeklySummary && (
                  <textarea
                    className="summary_textarea mt-0"
                    value={timesheet.weeklySummary}
                    readOnly
                    placeholder="Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary "
                  />
                )}
              </React.Fragment>
            ))}
            <div className="p-4 border_r_16px border">
              <div className="d-flex justify-content-between align-items-center mb-2">
                {/* <p className="font_14">
                  ${hourlyRate}/hr x {state.totalHours} hrs
                </p> */}
                <div>
                  {currentInvoice?.timesheets?.map((item: any, index: any) => (
                    <React.Fragment key={index}>
                      <p className="font_14">
                        ${onCalculatePerHourRate(item)}/hr x {item.totalHours}{" "}
                        hrs
                      </p>
                      {index + 1 !== currentInvoice.timesheets.length && (
                        <div className="text-center">+</div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
                <p className="font_14 text-end">
                  {/* ${hourlyRate * state.totalHours} */}$
                  {currentInvoice?.totalAmount || 0}
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-2">
                {/* <p className="font_14">20% Platform Fee</p> */}
                <p className="font_14">3% Processing Fee</p>
                <p className="font_14 text-end">
                  {/* ${((hourlyRate * state.totalHours) / 100) * 20} */}$
                  {processingFees}
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <p className="fw-bold fs-6">Total Week Invoice</p>
                <p className="text-end fw-bold fs-6">
                  ${Number(currentInvoice?.totalAmount || 0) + processingFees}
                  {/* {hourlyRate * state.totalHours +
                    ((hourlyRate * state.totalHours) / 100) * 20} */}
                </p>
              </div>
              {invoiceStatus() === InvoiceStatus.PAID ? (
                <button
                  type="button"
                  className="btn bg_light_secondary w-100 text_secondary"
                  data-bs-toggle="modal"
                  data-bs-target="#weekly_summary"
                >
                  Invoice Paid
                </button>
              ) : (
                <button
                  type="button"
                  className={`btn bg_secondary w-100 text_secondary ${
                    state.isBtnDisable ? "bg-rw-disable" : ""
                  }`}
                  data-bs-toggle="modal"
                  data-bs-target="#weekly_summary"
                  onClick={async () => {
                    setState({ isBtnDisable: true });
                    await handlePayInvoice();
                    setState({ isBtnDisable: false });
                  }}
                >
                  Pay Student
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TimeSheetDetails;
