import {
  useFormContext,
  FieldError,
  get,
  ValidationRule,
  Controller,
} from "react-hook-form";
import { PatternFormat } from "react-number-format";

interface FormPhoneInputProps {
  name: string;
  label?: string;
  placeholder?: string;
  type?: string;
  isRequired?: boolean;
  validation?: {
    minLength?: ValidationRule<number>;
    maxLength?: ValidationRule<number>;
    min?: ValidationRule<number>;
    max?: ValidationRule<number>;
    pattern?: ValidationRule<RegExp>;
  };
  formOptions?: any; // https://react-hook-form.com/api/useform/register
  disabled?: boolean;
}

export const FormPhoneInput: React.FC<FormPhoneInputProps> = ({
  name,
  label,
  isRequired = true,
  disabled = false,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error: FieldError = get(errors, name);

  return (
    <div className="flex flex-col gap-3 w-full">
      {label && (
        <div className="flex items-center gap-1">
          <label htmlFor={name} className="text-sm">
            {label}
          </label>
        </div>
      )}
      <div className="flex flex-col gap-1">
        <Controller
          render={({ field: { onChange, name, value } }) => (
            <PatternFormat
              format="+1 (###) ### ####"
              onValueChange={(v: any) => {
                onChange(v.value);
              }}
              name={name}
              className="input_style"
              disabled={disabled}
              value={value}
            />
          )}
          name={name}
          control={control}
          rules={{ required: isRequired }}
        />
        {error && (
          <div className="flex flex-col text-xs text-red-600">
            {!!error.types &&
              Object.values(error.types).map((message) => (
                <p key={message as string}>{message}</p>
              ))}
            {error.type === "required" && "Required"}
          </div>
        )}
      </div>
    </div>
  );
};
