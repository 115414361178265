import React, { useEffect, useRef } from "react";
import { useSnackbar } from "notistack";
import { useSearchParams, useNavigate } from "react-router-dom";
import humanize from "humanize-string";
import moment from "moment";
import Button from "components/Button";
import AppLoader from "components/AppLoader";
import ExploreProjectsFilter from "components/ExploreProjectsFilter";
import SearchProjects from "components/SearchProjects";
import ActiveProjectsFilter from "components/ActiveProjectsFilter";
import UserDetails from "components/UserDetails";
import Description from "components/Projects/Description";
// import { verifyFinishedPersonalityTests } from "./helper";
import { wrapHTMLContent } from "utils/string";
import { useMergeState, useWindowSize } from "utils/custom-hooks";
import menuBack from "../../../assets/imgs/menu-back.svg";
import Images from "assets/images";

import {
  AccountType,
  BudgetType,
  ProjectStatus,
  StudentProjectIntent,
  ProjectType,
  StudentProjectStatus,
} from "utils/constants";
import {
  projectHasStudent,
  timesAgo,
  wrapFullName,
  wrapNameTwoLetters,
} from "utils/common";
import { listProjects, updateStudentProject, getProjectDetails } from "api";
import { formatDate } from "utils/date";
import { verifyFinishedPersonalityTests } from "components/Projects/StudentProjectDetails/helper";
import LoginDialog from "components/LoginDialog";
import CompletePersonalityTestsDialog from "components/CompletePersonalityTestsDialog";
import SearchProjectMobileView from "./searchProjectMobileView";

type Props = {
  user: any;
  isLoggedIn: boolean;
  manuBarExpend: boolean;
  setUser: (user: any) => void;
  manuBarToogle: () => void;
};

const studentsCount = (studentProjects: Array<any>, user: any) => {
  const appliedStudents = studentProjects.filter(
    (studentProject) => !studentProject.suggested
  ).length;

  let message = "";

  if (user.accountType === AccountType.BUSINESS) {
    message = appliedStudents === 1 ? "Candidate" : "Candidates";
  }

  if (user.accountType === AccountType.STUDENT) {
    message = appliedStudents === 1 ? "Applicant" : "Applicants";
  }

  return `${appliedStudents} ${message}`;
};

// const isRequestedToInterview = (studentProjects: Array<any>, user: any) =>
//   studentProjects.some(
//     (elem) =>
//       elem?.student?._id === user?._id &&
//       elem?.status === StudentProjectStatus.REQUESTED_TO_INTERVIEW
//   );

// const getFinishedStudents = (studenProjects: Array<any>) =>
//   studenProjects.filter(
//     (elem: any) => elem?.status === StudentProjectStatus.FINISHED
//   );

// const getOngoingStudents = (studenProjects: Array<any>) =>
//   studenProjects.filter(
//     (elem: any) => elem?.status === StudentProjectStatus.IN_PROGRESS
//   );

const getProjectRate = (project: any) => {
  if (project.budgetType === BudgetType.FULL_TIME) {
    return "FULL TIME POSITION";
  }

  if (project.budgetType === BudgetType.RETAINER) {
    return `${project.studentRetainerAmount}`;
  }

  if (project.budgetType === BudgetType.HOURLY_RATE) {
    return `${project.studentHourlyRate}`;
  }
};

const ProjectTypeFilters = [
  {
    title: "All Opportunities",
    value: "ALL OPPORTUNITIES",
  },
  {
    title: "Development",
    value: ProjectType.DEVELOPER,
  },
  {
    title: "Engineering",
    value: ProjectType.ENGINEER,
  },
  {
    title: "Business",
    value: ProjectType.BUSINESS,
  },
];

const AllOpportunities = ["DEVELOPER", "ENGINEER", "BUSINESS"];

const DateFilters = [
  {
    title: "All",
    value: "All",
  },
  {
    title: "New to Old",
    value: "New to Old",
  },
  {
    title: "Old to New",
    value: "Old to New",
  },
  {
    title: "Most to Least Applicants",
    value: "Most to Least Applicants",
  },
  {
    title: "Least to Most Applicants",
    value: "Least to Most Applicants",
  },
  {
    title: "Last 3 Days",
    value: "Last 3 Days",
  },
  {
    title: "Last 7 Days",
    value: "Last 7 Days",
  },
];

export default function StudentSearchProjectsContainer({
  user,
  manuBarExpend,
  isLoggedIn,
  setUser,
  manuBarToogle,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const currentWidth = useWindowSize().width;
  const isDesktopView = currentWidth > 650;

  const [searchParams, setSearchParams] = useSearchParams();

  const projectStatus = searchParams.getAll("status");
  const projectTypes = searchParams.getAll("types");

  const isExplore = searchParams.get("explore") || "";
  const projectIdFromParams = searchParams.get("id") || "";
  const isHandleSearchSubmit = useRef(false);

  const [state, setState] = useMergeState({
    isLoading: false,

    companyLogoUrls: null,
    projects: [],
    status: [],
    types: [],
    search: "",

    shouldShowFilterMenu: false,
    isShowFilterMenu: false,
    isShowDateMenu: false,
    selectedProject: {},
    studentProject: null,
    jobOpportunityFilter: "ALL OPPORTUNITIES",
    dateFilter: "All",
    shouldShowLoginDialog: false,
    showCompletePersonalityTests: false,
    isShowNavBarInMobileView: false,
    isDisableBtn: false,
  });

  const onShowDateMenu = () => {
    setState({
      isShowDateMenu: !state.isShowDateMenu,
      isShowFilterMenu: false,
    });
  };

  const onShowFilterMenu = () => {
    setState({
      isShowFilterMenu: !state.isShowFilterMenu,
      isShowDateMenu: false,
    });
  };

  const onClickProject = (project: any) => {
    const { studentProject } = projectHasStudent({
      project,
      studentId: user?._id,
    });
    setState({
      studentProject,
      selectedProject: project,
    });
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    isHandleSearchSubmit.current = true;
    setState({ search: event?.target?.value });
  };

  const handleSearchSubmit = async () => {
    try {
      const params: any = {
        search: state?.search,
        status: [],
        types: state?.types,
        duration: state.dateFilter,
        explore: isExplore,
      };

      if (user?.accountType === AccountType.BUSINESS) {
        params.business = user?.business?._id;
      }

      if (user?.accountType === AccountType.STUDENT) {
        params.student = user?._id;
      }

      const response = await listProjects(params);
      const project = response.data[0];

      const { studentProject } = projectHasStudent({
        project,
        studentId: user?._id,
      });

      if (response?.success) {
        setState({
          projects: response?.data,
          studentProject,
          selectedProject: response?.data[0],
        });
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleOpenShouldShowLoginDialog = () => {
    setState({ shouldShowLoginDialog: true });
  };

  const handleCloseShouldShowLoginDialog = () => {
    setState({ shouldShowLoginDialog: false });
  };

  const toggleCompletePersonalityTests = () => {
    setState({
      showCompletePersonalityTests: !state.showCompletePersonalityTests,
    });
  };

  const handleUpdateStudentProjectStatus = async (
    intent: string,
    projectId: string
  ) => {
    try {
      if (!user?._id) {
        handleOpenShouldShowLoginDialog();
        return;
      }

      if (
        !verifyFinishedPersonalityTests(user, state?.selectedProject?.type) &&
        !state?.project?.business?.atv?.isATV
      ) {
        toggleCompletePersonalityTests();
        return;
      }

      setState({ isDisableBtn: true });
      const response = await updateStudentProject({ id: projectId, intent });
      const message =
        intent === StudentProjectIntent.APPLY
          ? "Applied to project successfully."
          : "Un-applied from project successfully.";

      if (response?.success) {
        const project = response?.data;

        const { studentProject } = projectHasStudent({
          project,
          studentId: user?._id,
        });

        const projectsCopy = state.projects.slice();
        const selectProjectIndex = projectsCopy.findIndex(
          (item: any) => item._id === projectId
        );
        projectsCopy[selectProjectIndex] = project;

        setState({
          studentProject,
          projects: projectsCopy,
          isDisableBtn: false,
        });

        enqueueSnackbar(message, {
          variant: "success",
        });
      } else {
        setState({ isDisableBtn: false });
      }
    } catch (error: any) {
      setState({ isDisableBtn: false });
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const init = async ({
    types = [],
    duration = "All",
  }: {
    types?: Array<string>;
    duration: string;
  }) => {
    try {
      setState({ isLoading: true });

      const params: any = {
        status: [],
        types,
        duration,
        explore: isExplore,
      };

      if (user?.accountType === AccountType.BUSINESS) {
        params.business = user?.business?._id;
      }

      if (user?.accountType === AccountType.STUDENT) {
        params.student = user?._id;
      }

      const response = await listProjects(params);
      const project =
        projectIdFromParams !== ""
          ? response?.data.find((item: any) => item._id === projectIdFromParams)
          : response?.data[0];

      const { studentProject } = projectHasStudent({
        project,
        studentId: user?._id,
      });

      setState({
        projects: response?.data,
        companyLogoUrls: response?.companyLogoUrls || null,
        studentProject,
        selectedProject: project,
        types,
        jobOpportunityFilter:
          types.length === 3 ? "ALL OPPORTUNITIES" : types[0],
      });
      setSearchParams({ types, explore: isExplore }, { replace: true });
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    } finally {
      setState({ isLoading: false });
    }
  };

  const handleChangeOpportunityFilter = (value: string) => {
    isHandleSearchSubmit.current = false;
    if (value === "ALL OPPORTUNITIES") {
      init({ types: AllOpportunities, duration: state.dateFilter });
    } else {
      init({ types: [value], duration: state.dateFilter });
    }
    setState({
      search: "",
      jobOpportunityFilter: value,
      isShowFilterMenu: false,
    });
  };

  const handleChangeDateFilter = (value: string) => {
    isHandleSearchSubmit.current = false;
    if (state.jobOpportunityFilter === "ALL OPPORTUNITIES") {
      init({ types: AllOpportunities, duration: value });
    } else {
      init({ types: [state.jobOpportunityFilter], duration: value });
    }
    setState({
      search: "",
      dateFilter: value,
      isShowDateMenu: false,
    });
  };

  useEffect(() => {
    if (!isHandleSearchSubmit.current) return;
    const timeoutId = setTimeout(async () => {
      await handleSearchSubmit();
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [state.search]);

  useEffect(() => {
    let types: Array<string> = [];

    if (projectTypes.length > 0) {
      types = projectTypes;
    } else if (user?.profileTypes) {
      types = user.profileTypes?.slice(0, 1);
    } else {
      types = AllOpportunities;
    }
    init({ types, duration: state.dateFilter });
    setTimeout(() => {
      isHandleSearchSubmit.current = true;
    }, 1000);
  }, []);

  // useEffect(() => {
  //   if (currentWidth > 650) {
  //     setState({ desktopMode: true });
  //   } else {
  //     setState({ desktopMode: false });
  //   }
  // }, [useWindowSize().width]);

  const initials = (name: string) => (
    <div className="profile_image" style={{ width: 50, height: 50 }}>
      <p className="profileText fs-5">
        {wrapNameTwoLetters(
          name.split(" ")[0],
          name.split(" ")[1]
        ).toUpperCase()}
      </p>
    </div>
  );

  const isProjectHasStudent = (project: any) => {
    const { studentProject } = projectHasStudent({
      project,
      studentId: user?._id,
    });
    return studentProject;
  };

  const isCompanyLogoFound = (id: any) =>
    state.companyLogoUrls && state.companyLogoUrls[id];

  return state?.isLoading ? (
    <AppLoader isLoading={state?.isLoading} />
  ) : (
    <div className={!isLoggedIn ? "main_content login_state" : "main_content"}>
      {isLoggedIn && (
        <header
          className={`header ${manuBarExpend ? "body_pd" : ""}`}
          id="header"
        >
          <div className="header_toggle" onClick={() => manuBarToogle()}>
            <i
              className={`bx bx-menu ${!manuBarExpend ? "bx-x" : ""}`}
              id="header_toggle"
            />
          </div>
          <div className="fw-bold fs-5 ms-3 mb-0 d-flex align-items-center w-100">
            <span className="d-block d-md-none me-3">
              <img src={Images.Runaway_logo} />
            </span>
            <span className="d-none d-md-block">Search Projects</span>
          </div>
        </header>
      )}
      <div
        className={
          isDesktopView
            ? "bg-white d-flex justify-content-between align-items-center px-3 px-md-4 w-100 flex-wrap flex-md-nowrap py-2"
            : "bg-white d-flex justify-content-between p-3 p-md-4 w-100 flex-wrap flex-md-nowrap"
        }
      >
        <div className="search_icon">
          <span>
            <i className="fa-regular fa-magnifying-glass" />
          </span>
          <input
            type="text"
            name=""
            value={state?.search}
            onChange={handleSearchChange}
            placeholder="Search Job Title or Key Word"
            className="input_search"
          />
        </div>
        <div className="d-flex justify-content-between justify-content-md-start mob_100 mt-3 mt-md-0">
          <div className="dropdown custom_dropdown w-100">
            <button
              className="dropdown-toggle"
              type="button"
              onClick={onShowFilterMenu}
            >
              <p className="font_14 fw-bold text_primary text-left">
                {state.jobOpportunityFilter}
              </p>
              <p className="text-start font_12">Job Opportunities</p>
            </button>
            <ul
              className={`dropdown-menu ${
                state.isShowFilterMenu ? "show" : ""
              }`}
              aria-labelledby="dropdownMenuButton1"
            >
              {ProjectTypeFilters.map((item: any, index: number) => (
                <li key={index}>
                  <div
                    className="dropdown-item"
                    onClick={() => handleChangeOpportunityFilter(item.value)}
                  >
                    {item.title}
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="dropdown custom_dropdown w-100 ms-2 ms-md-3">
            <button
              className="dropdown-toggle"
              type="button"
              onClick={onShowDateMenu}
            >
              <p className="font_14 fw-bold text_primary text-left">
                {state.dateFilter}
              </p>
              <p className="text-start font_12">Date Posted</p>
            </button>
            <ul
              className={`dropdown-menu ${state.isShowDateMenu ? "show" : ""}`}
              aria-labelledby="dropdownMenuButton1"
            >
              {DateFilters.map((item: any, index: number) => (
                <li key={index}>
                  <div
                    className="dropdown-item"
                    onClick={() => handleChangeDateFilter(item.value)}
                  >
                    {item.title}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {isDesktopView ? (
        <div className="bg_light w-100 overflow-hidden">
          {state.projects.length > 0 ? (
            <div className="d-flex align-items-start flex-wrap flex-md-nowrap">
              <div
                className="nav flex-column nav-pills me-0 me-md-3 vertical_tabs w-50 bg-white border border_gray mob_100"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                {state.projects.map((project: any) => (
                  <button
                    key={project._id}
                    className={`nav-link bg-transparent pt-4 ${
                      state.selectedProject?._id === project._id ? "active" : ""
                    }`}
                    onClick={() => onClickProject(project)}
                  >
                    <div className="box_window">
                      <div className="d-flex align-items-center">
                        {isCompanyLogoFound(project._id) ? (
                          <div className="lg_stack_logo">
                            <img src={isCompanyLogoFound(project._id)} />
                          </div>
                        ) : project?.business && project?.business?.name ? (
                          initials(project?.business.name)
                        ) : (
                          <div className="lg_stack_logo">
                            <img src={Images.Stack} />
                          </div>
                        )}
                        <div>
                          <p className="text_black fw-bold fs-6 ms-3 lh-sm text-start">
                            {project.title}
                          </p>
                          <p className="font_12 text_primary ms-3 text-start">
                            {project?.business?.name || ""}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex flex-wrap mt-4">
                        {project.skills
                          .slice(0, 3)
                          .map((skill: string, index: string) => (
                            <span key={index} className="tag">
                              {skill}
                            </span>
                          ))}
                        {project.skills.length > 3 && (
                          <span className="tag">
                            {`+${project.skills.length - 3}`}
                          </span>
                        )}
                      </div>
                      <div className="border-bottom border_gray my-2" />
                      <div className="d-flex align-items-center justify-content-between mt-3">
                        {!isProjectHasStudent(project) ? (
                          <div className="d-flex">
                            <p className="text_primary font_14">
                              {timesAgo(project?.createdAt)}
                            </p>
                            <p className="text_primary ms-1 d-flex align-items-center font_14">
                              <span className="dot" />
                              {`${studentsCount(
                                project.studentProjects,
                                user
                              )}`}
                            </p>
                          </div>
                        ) : (
                          <p className="font_14 fw_500 text_secondary bg_light_secondary px-3 py-2 border_r_8px">
                            <span className="me-2">
                              <i className="fa-duotone fa-check" />
                            </span>
                            Applied
                          </p>
                        )}
                        <p className="font_12 fw-bold text_black">
                          {/* {`$${project.hourlyRate}/HR`} */}
                          {`$${getProjectRate(project)}/HR`}
                        </p>
                      </div>
                    </div>
                  </button>
                ))}
              </div>
              <div
                className="tab-content w-100 mt-3 mt-md-0 me-0 me-md-3"
                id="v-pills-tabContent"
              >
                <div
                  className="tab-pane fade show active w-100"
                  id="v-pills-tab01"
                  role="tabpanel"
                  aria-labelledby="v-pills-main-tab01"
                >
                  <div className="p-3 p-md-4 bg-white w-100 border border_gray border_r_16px mt-3 border_sm_r_0">
                    <div className="d-flex align-items-center">
                      {/* <div className="lg_stack_logo">
                        <img src={Images.Stack} />
                      </div> */}
                      {isCompanyLogoFound(state.selectedProject?._id) ? (
                        <div className="lg_stack_logo">
                          <img
                            src={isCompanyLogoFound(state.selectedProject._id)}
                          />
                        </div>
                      ) : state.selectedProject?.business &&
                        state.selectedProject?.business?.name ? (
                        initials(state.selectedProject?.business.name)
                      ) : (
                        <div className="lg_stack_logo">
                          <img src={Images.Stack} />
                        </div>
                      )}
                      <div>
                        <p className="text_black fw-bold fs-5 ms-3 lh-base">
                          {state.selectedProject?.title || ""}
                        </p>
                        <p className="font_14 text_primary ms-3 text-start">
                          {state.selectedProject?.business?.name || ""}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-4 flex-wrap">
                      <div className="d-flex align-items-center flex-wrap">
                        {state.selectedProject?.hourlyRate && (
                          <div className="d-flex bg_main p-2 border_r_8px mb-3 mb-md-0">
                            <span className="text_black fw-bold">
                              {/* {`$${state.selectedProject?.hourlyRate || 0}/hr`} */}
                              {`$${getProjectRate(state.selectedProject)}/hr`}
                            </span>
                            <span className="px-3">|</span>
                            <span className="text_black fw-bold">
                              {`${state.selectedProject?.duration || 0} Weeks`}
                            </span>
                          </div>
                        )}
                        {state.selectedProject?.createdAt && (
                          <div className="d-flex mb-3 mb-md-0">
                            <p className="text_primary ms-3">
                              {timesAgo(state.selectedProject?.createdAt)}
                            </p>
                            <p className="text_primary ms-1 d-flex align-items-center">
                              <span className="dot" />
                              {`${studentsCount(
                                state?.selectedProject?.studentProjects,
                                user
                              )}`}
                            </p>
                          </div>
                        )}
                      </div>
                      {state?.studentProject && (
                        <button
                          type="button"
                          className="btn bg_light_secondary btn_small mob_100 text_secondary"
                        >
                          <span className="me-2">
                            <i className="fa-sharp fa-regular fa-check" />
                          </span>
                          Applied
                        </button>
                      )}
                      {!state?.studentProject && (
                        <button
                          disabled={state.isDisableBtn}
                          type="button"
                          className={`btn bg_secondary btn_small mob_100 ${
                            state.isDisableBtn ? "bg-rw-disable" : ""
                          }`}
                          onClick={() =>
                            handleUpdateStudentProjectStatus(
                              StudentProjectIntent.APPLY,
                              state?.selectedProject._id
                            )
                          }
                        >
                          Quick Apply
                        </button>
                      )}
                    </div>
                    <div className="border-bottom box_shadow w-100 my-4 box_shadow_dark" />
                    <div className="project_description">
                      <p className="font_18 fw-bold text_primary mb-2">
                        Description
                      </p>
                      <div
                        className="mt-2 text-rw-gray-75"
                        dangerouslySetInnerHTML={{
                          __html: wrapHTMLContent(
                            state?.selectedProject?.description
                          ),
                        }}
                      />
                      <p className="font_18 fw-bold text_primary mb-2 mt-4">
                        Key Responsibilities
                      </p>
                      <div
                        className="mt-2 text-rw-gray-75"
                        dangerouslySetInnerHTML={{
                          __html: wrapHTMLContent(
                            state.selectedProject?.keyResponsibilities
                          ),
                        }}
                      />
                      <p className="font_18 fw-bold text_primary mb-2 mt-4">
                        Qualifications
                      </p>
                      <div
                        className="mt-2 text-rw-gray-75"
                        dangerouslySetInnerHTML={{
                          __html: wrapHTMLContent(
                            state.selectedProject?.qualifications
                          ),
                        }}
                      />
                      <p className="font_18 fw-bold text_primary mb-2 mt-4">
                        Skills
                      </p>
                      <div className="d-flex flex-wrap mt-4">
                        {state.selectedProject?.skills &&
                          state.selectedProject.skills.map(
                            (skill: string, index: string) => (
                              <span key={index} className="tag">
                                {skill}
                              </span>
                            )
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="d-flex align-items-center justify-content-center">
              <p className="text_black fw-bold fs-5 ms-3 lh-base">
                No Projects Found
              </p>
            </div>
          )}
        </div>
      ) : (
        <SearchProjectMobileView
          user={user}
          projects={state.projects}
          companyLogoUrls={state.companyLogoUrls}
          initials={initials}
        />
      )}
      {state?.shouldShowLoginDialog && (
        <LoginDialog
          open={state?.shouldShowLoginDialog}
          onClose={handleCloseShouldShowLoginDialog}
          setUser={setUser}
        />
      )}
      {state.showCompletePersonalityTests && (
        <CompletePersonalityTestsDialog
          projectType={state.selectedProject?.type}
          projectId={state.selectedProject?._id}
          open={state.showCompletePersonalityTests}
          onClose={toggleCompletePersonalityTests}
        />
      )}
    </div>
  );
}
