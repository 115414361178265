import { BusinessPaymentDetails } from "./BusinessPaymentDetails";
import { BusinessNotificationDetails } from "./BusinessNotificationDetails";

interface BusinessSettingsPaneProps {
  user: any;
}

export const BusinessSettingsPane: React.FC<BusinessSettingsPaneProps> = ({
  user,
}) => {
  return (
    <div className="flex gap-4 mt-3 w-full flex-col lg:flex-row">
      <BusinessPaymentDetails user={user} />
      <BusinessNotificationDetails user={user} />
    </div>
  );
};
