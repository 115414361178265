import React, { useEffect } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Elements } from "@stripe/react-stripe-js";
// eslint-disable-next-line import/no-extraneous-dependencies
import { loadStripe } from "@stripe/stripe-js";
import { useSnackbar } from "notistack";
import DialogContent from "@mui/material/DialogContent";
import { Dialog, DialogTitle } from "components/Dialog";
import Spinner from "components/Spinner";
import CardSetupForm from "./CardSetupForm";
import { createSetupIntent } from "api";
import { useMergeState } from "utils/custom-hooks";

let stripePromise = loadStripe(process.env?.REACT_APP_STRIPE_PUBLIC_KEY || "");

type Props = {
  user: any;
  open: boolean;
  onClose: (param: boolean) => void;
};

export default function AddNewCardDialog({ user, open, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useMergeState({
    isCreatingSetupIntent: false,
    clientSecret: null,
  });

  useEffect(() => {
    stripePromise = loadStripe(process.env?.REACT_APP_STRIPE_PUBLIC_KEY || "");
  }, []);

  const init = async () => {
    try {
      setState({ isCreatingSetupIntent: true });

      const response = await createSetupIntent();

      if (response?.success) {
        setState({
          isCreatingSetupIntent: false,
          clientSecret: response?.data?.clientSecret,
        });
      }
    } catch (error: any) {
      setState({ isCreatingSetupIntent: false });
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  React.useEffect(() => {
    init();
  }, []);

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose(false);
        }
      }}
      open={open}
      maxWidth="xs"
      fullWidth
      disableEscapeKeyDown
    >
      <DialogTitle onClose={onClose}>
        <span className="text-xl font-semibold">Add New Card</span>
      </DialogTitle>

      <DialogContent dividers>
        {state?.isCreatingSetupIntent && (
          <div className="flex justify-center mt-4">
            <Spinner loading={state?.isCreatingSetupIntent} />
          </div>
        )}

        {stripePromise &&
          state?.clientSecret &&
          !state?.isCreatingSetupIntent && (
            <div className="mt-4">
              <Elements stripe={stripePromise}>
                <CardSetupForm
                  user={user}
                  clientSecret={state?.clientSecret}
                  onClose={() => onClose(true)}
                />
              </Elements>
            </div>
          )}
      </DialogContent>
    </Dialog>
  );
}
