import React, { useEffect } from "react";
import { useMergeState } from "utils/custom-hooks";
// import { createTimesheets, getTimesheets } from "api";
import { Modal } from "react-bootstrap";
import { formatDate, getDayByIndex } from "utils/date";

// function getNext7DaysLoop(startDate: any) {
//   const currentDate = new Date(startDate);
//   const next7Days = [];
//   for (let i = 1; i <= 7; i++) {
//     const nextDate = new Date(currentDate);
//     nextDate.setDate(currentDate.getDate() + i);
//     next7Days.push(nextDate.toISOString().slice(0, 10));
//     // next7Days.push({ day: nextDate.toISOString().slice(0, 10), hours: 0 });
//   }
//   return next7Days;
// }

// const dayNames = [
//   "Sunday",
//   "Monday",
//   "Tuesday",
//   "Wednesday",
//   "Thursday",
//   "Friday",
//   "Saturday",
// ];

type Props = {
  // projectId: string;
  // hourlyRate: number;
  // platformFee: number;
  hasPrev: boolean;
  hasNext: boolean;
  isCapRate: boolean;
  hoursPerWeek: number;
  currentTimesheet: any;
  handlePreviousWeek: any;
  handleNextWeek: any;
  handleChangeHours: any;
  handleClearTimesheet: any;
  handleSubmitTimesheet: (summary: string) => any;
};

const SubmitHours = ({
  // projectId,
  // hourlyRate,
  // platformFee,
  hasPrev,
  hasNext,
  isCapRate,
  hoursPerWeek,
  currentTimesheet,
  handlePreviousWeek,
  handleNextWeek,
  handleChangeHours,
  handleClearTimesheet,
  handleSubmitTimesheet,
}: Props) => {
  const [state, setState] = useMergeState({
    showSummaryModal: false,
    weeklySummary: "",
    isWeeklySummayAdded: false,
    isBtnDisable: false,
  });

  const handleSubmit = async () => {
    try {
      setState({ isBtnDisable: true });
      await handleSubmitTimesheet(state.weeklySummary);
      setState({
        isWeeklySummayAdded: false,
        weeklySummay: "",
      });
    } finally {
      setState({ isBtnDisable: false });
    }
  };

  const onCalculatePerHourRate = () => {
    const studentTotalAmount = Number(currentTimesheet?.studentTotalAmount);
    const totalHours = Number(currentTimesheet?.totalHours);
    return studentTotalAmount / totalHours;
  };

  const onCalculateTotalHours = () => {
    const total = currentTimesheet?.timesheet?.reduce(
      (total: any, item: any) => total + Number(item.hours),
      0
    );
    return total;
  };

  const isDisableSubmitBtn = () => {
    if (isCapRate) {
      if (onCalculateTotalHours() > hoursPerWeek) {
        return true;
      }
    }
  };

  const isPositiveNumber = (value: string) => {
    const num = Number(value);
    return !Number.isNaN(num) && num >= 0 && num <= 24;
  };

  const handleHours = (index: number, value: string) => {
    if (isPositiveNumber(value)) handleChangeHours(index, value.toString());
  };

  return (
    <div>
      <div className="d-flex justify-content-center pt-4">
        <span
          className={`fs-5 ${
            !state.isWeeklySummayAdded && hasPrev
              ? "text_secondary cursor_pointer"
              : "text_light_primary"
          }`}
          onClick={
            !state.isWeeklySummayAdded && hasPrev ? handlePreviousWeek : null
          }
        >
          <i className="fa-regular fa-chevron-left" />
        </span>
        <div className="text-center px-5">
          <p className="font_12">WEEK START</p>
          <p className="font_14 text_secondary fw-bold">
            {formatDate(currentTimesheet.weekStart, "ddd MMM Do, YYYY")}
          </p>
        </div>
        <span
          className={`fs-5 ${
            !state.isWeeklySummayAdded && hasNext
              ? "text_secondary cursor_pointer"
              : "text_light_primary"
          }`}
          onClick={
            !state.isWeeklySummayAdded && hasNext ? handleNextWeek : null
          }
        >
          <i className="fa-regular fa-chevron-right" />
        </span>
      </div>
      <div className="border-bottom box_shadow w-100 my-4" />
      {/* Hour input */}
      {!currentTimesheet?.hasTimesheet && !state.isWeeklySummayAdded ? (
        <div className="middle_box pb-5">
          {currentTimesheet?.timesheet?.map((elmt: any, index: number) => (
            <div className="mb-3" key={elmt?.day}>
              <label className="font_14 w-100 mb-2 fw-bold">
                {getDayByIndex(index)}
              </label>
              <input
                type="text"
                min="0"
                name={`${index}`}
                value={elmt.hours || ""}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  // handleChangeHours(index, event?.target?.value)
                  handleHours(index, event?.target?.value)
                }
                placeholder="Hours Worked"
                className="form-control input_style"
              />
            </div>
          ))}

          <div className="mb-3 d-flex align-items-center justify-content-between">
            <p className="font_14 fw-bold">Total</p>
            <p className="text_secondary fw_500">
              {onCalculateTotalHours()} Hours
            </p>
          </div>

          <button
            type="button"
            className="btn bg_secondary w-100 mb-3"
            data-bs-toggle="modal"
            data-bs-target="#weekly_summary"
            onClick={() => {
              if (onCalculateTotalHours() > 0) {
                setState({ showSummaryModal: true });
              }
            }}
          >
            Next
          </button>
          <button
            type="button"
            className="btn bg-transparent text_secondary w-100 border-2 border_secondary"
            onClick={handleClearTimesheet}
          >
            Clear
          </button>
        </div>
      ) : !currentTimesheet?.hasTimesheet && state.isWeeklySummayAdded ? (
        <div className="smaller_box pb-5">
          <div className="total_hours table-responsive">
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Total Hours</th>
                  <th scope="col" className="text-end text_secondary">
                    {onCalculateTotalHours()} Hours
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentTimesheet?.timesheet.map((elmt: any, index: number) => (
                  <tr key={index}>
                    <td>{getDayByIndex(index)}</td>
                    <td className="text-end">{Number(elmt.hours)} Hours</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <textarea
            className="summary_textarea mt-0"
            value={state.weeklySummary}
            readOnly
            placeholder={
              state.weeklySummary === ""
                ? "No summary provided"
                : "Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary "
            }
          />
          {isDisableSubmitBtn() && (
            <div className="flex justify-between mb-2">
              <div className="font-semibold text-sm">Total</div>
              <div>
                <div className="font-semibold text-sm text-rw-maroon">{`${onCalculateTotalHours()} Hours`}</div>
                <div className="font-semibold text-sm rw-black-slight-dark">{`${hoursPerWeek} Hours Max`}</div>
              </div>
            </div>
          )}
          <button
            disabled={state.isBtnDisable || isDisableSubmitBtn()}
            type="button"
            className={`btn w-100 mb-3 ${
              state.isBtnDisable || isDisableSubmitBtn()
                ? "bg-rw-disable"
                : "bg_secondary"
            }`}
            data-bs-toggle="modal"
            data-bs-target="#weekly_summary"
            onClick={() => handleSubmit()}
          >
            Submit
          </button>
          <button
            type="button"
            onClick={() =>
              setState({ isWeeklySummayAdded: false, weeklySummary: "" })
            }
            className="btn bg-transparent text_secondary w-100 border-2 border_secondary"
          >
            Back
          </button>
        </div>
      ) : (
        <div className="smaller_box">
          <div className="total_hours">
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Total Hours</th>
                  <th scope="col" className="text-end text_secondary">
                    {currentTimesheet.totalHours} Hours
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentTimesheet?.timesheet.map((elmt: any, index: number) => (
                  <tr key={index}>
                    <td>{getDayByIndex(index)}</td>
                    <td className="text-end">{Number(elmt.hours)} Hours</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="p-4 border_r_16px border">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <p className="font_14">
                ${onCalculatePerHourRate().toFixed(2)}/hr x
                {currentTimesheet.totalHours}hrs
              </p>
              <p className="font_14 text-end">
                ${onCalculatePerHourRate() * currentTimesheet.totalHours}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <p className="fw-bold fs-6">Total Week Invoice</p>
              <p className="text-end fw-bold fs-6">
                ${currentTimesheet.studentTotalAmount}
              </p>
            </div>
            <button
              type="button"
              className="btn bg_light_secondary w-100 text_secondary"
              data-bs-toggle="modal"
              data-bs-target="#weekly_summary"
            >
              Hours Submitted
            </button>
          </div>
        </div>
      )}

      {/* Modal */}
      <Modal
        show={state.showSummaryModal}
        onHide={() => setState({ showSummaryModal: false })}
        centered
      >
        <div className="p-3 p-md-4 border_r_16px overflow-hidden">
          <div className="modal-header text-end p-0 border-0">
            <button
              type="button"
              className="d-flex justify-content-end border-0 bg-transparent w-100 fs-4 text_secondary"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setState({ showSummaryModal: false })}
            >
              <i className="fa-regular fa-xmark" />
            </button>
          </div>
          <div className="modal-body p-0">
            <p className="fs-4 text-start text_black lh-sm mb-1">
              Weekly Summary
            </p>
            <p className="fs-6 text_primary text-start">
              Summarize what you completed this week.
            </p>
            <textarea
              className="summary_textarea"
              placeholder="Enter a summary of your work for this week."
              onChange={(e: any) => {
                setState({ weeklySummary: e.target.value });
              }}
            />
            <button
              type="button"
              className="btn bg_secondary w-100 mb-3"
              onClick={() => {
                setState({
                  isWeeklySummayAdded: true,
                  showSummaryModal: false,
                });
              }}
            >
              Next
            </button>
            <button
              type="button"
              className="btn bg-transparent text_secondary w-100 border-2 border_secondary"
              onClick={() =>
                setState({
                  isWeeklySummayAdded: true,
                  showSummaryModal: false,
                  weeklySummary: "",
                })
              }
            >
              Skip
            </button>
          </div>
        </div>
      </Modal>
      {/* modal end */}
    </div>
  );
};

export default SubmitHours;
