import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import AppLoader from "components/AppLoader";
import { wrapHTMLContent } from "utils/string";
import { useMergeState } from "utils/custom-hooks";
import Images from "assets/images";

import { StudentProjectIntent } from "utils/constants";
import { projectHasStudent, wrapNameTwoLetters } from "utils/common";
import { updateStudentProject, getProjectDetails } from "api";
import { verifyFinishedPersonalityTests } from "components/Projects/StudentProjectDetails/helper";
import LoginDialog from "components/LoginDialog";
import CompletePersonalityTestsDialog from "components/CompletePersonalityTestsDialog";

type Props = {
  user: any;
  isLoggedIn: boolean;
  setUser: (user: any) => void;
};

export default function ProjectDetailsMobileView({
  user,
  isLoggedIn,
  setUser,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get("id") || "";
  const [state, setState] = useMergeState({
    isLoading: false,
    project: {},

    projects: [],
    companyLogoUrl: null,

    shouldShowFilterMenu: false,
    isShowFilterMenu: false,
    isShowDateMenu: false,
    studentProject: null,
    shouldShowLoginDialog: false,
    showCompletePersonalityTests: false,
    isShowNavBarInMobileView: false,
    isDisableBtn: false,
  });

  const handleOpenShouldShowLoginDialog = () => {
    setState({ shouldShowLoginDialog: true });
  };

  const handleCloseShouldShowLoginDialog = () => {
    setState({ shouldShowLoginDialog: false });
  };

  const toggleCompletePersonalityTests = () => {
    setState({
      showCompletePersonalityTests: !state.showCompletePersonalityTests,
    });
  };

  const handleUpdateStudentProjectStatus = async (
    intent: string,
    projectId: string
  ) => {
    try {
      if (!user?._id) {
        handleOpenShouldShowLoginDialog();
        return;
      }

      if (
        !verifyFinishedPersonalityTests(user, state?.project?.type) &&
        !state?.project?.business?.atv?.isATV
      ) {
        toggleCompletePersonalityTests();
        return;
      }

      setState({ isDisableBtn: true });
      const response = await updateStudentProject({ id: projectId, intent });

      const message =
        intent === StudentProjectIntent.APPLY
          ? "Applied to project successfully."
          : "Un-applied from project successfully.";

      if (response?.success) {
        const project = response?.data;

        const { studentProject } = projectHasStudent({
          project,
          studentId: user?._id,
        });

        // const projectsCopy = state.projects.slice();
        // const selectProjectIndex = projectsCopy.findIndex(
        //   (item: any) => item._id === projectId
        // );
        // projectsCopy[selectProjectIndex] = project;

        setState({
          project,
          studentProject,
          isDisableBtn: false,
        });

        enqueueSnackbar(message, {
          variant: "success",
        });
      } else {
        setState({ isDisableBtn: false });
      }
    } catch (error: any) {
      setState({ isDisableBtn: false });
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const init = async () => {
    try {
      setState({ isLoading: true });

      const response = await getProjectDetails(projectId);
      const project = response?.data;

      const { studentProject } = projectHasStudent({
        project,
        studentId: user?._id,
      });

      setState({
        project,
        projects: response?.data,
        companyLogoUrl: response?.companyLogoUrl,
        studentProject,
      });
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    } finally {
      setState({ isLoading: false });
    }
  };

  useEffect(() => {
    init();
  }, []);

  const initials = (name: string) => (
    <div className="profile_image" style={{ width: 50, height: 50 }}>
      <p className="profileText fs-5">
        {wrapNameTwoLetters(
          name.split(" ")[0],
          name.split(" ")[1]
        ).toUpperCase()}
      </p>
    </div>
  );

  return state?.isLoading ? (
    <AppLoader isLoading={state?.isLoading} />
  ) : (
    <div className={!isLoggedIn ? "main_content login_state" : "main_content"}>
      <header className="header body_pd" id="header">
        <div className="fw-bold fs-5 ms-3 mb-0 d-flex align-items-center w-100">
          <span className="d-block d-md-none me-3">
            <img src={Images.Runaway_logo} />
          </span>
          {!isLoggedIn && (
            <nav className="navbar navbar-expand-lg d-md-none w-100">
              <div className="container-fluid">
                <button
                  className="navbar-toggler w-100 text-end collapsed"
                  type="button"
                  onClick={() =>
                    setState({
                      isShowNavBarInMobileView: !state.isShowNavBarInMobileView,
                    })
                  }
                >
                  <span className="navbar-toggler-icon text_secondary mt-2">
                    <i className="fa-regular fa-bars" />
                  </span>
                </button>
                <div
                  className={`navbar-collapse justify-content-between w-100 align-items-center py-3 ${
                    !state.isShowNavBarInMobileView ? "collapse" : ""
                  }`}
                  id="main_navbar"
                >
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0 align-items-start align-items-md-center">
                    <li className="nav-item d-none d-md-block">
                      <a className="nav-link active" href="/">
                        <img src={Images.Runaway_logo} />
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/">
                        Projects
                      </a>
                    </li>
                  </ul>
                  <ul className="menu2">
                    <li>
                      <a
                        href="/"
                        className="btn btn_small bg_light_secondary rounded-pill text_secondary"
                      >
                        For Business
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="btn btn_small bg_secondary mx-0 mx-md-3"
                      >
                        Apply as Student
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="btn btn_small bg_darkish text-white"
                      >
                        Login
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          )}
        </div>
      </header>
      <div className="bg_light w-100 overflow-hidden mb-5 mb-md-0 pb-5 pb-md-4">
        <div className="p-3 p-md-4 bg-white w-100 border border_gray border_r_16px mt-3 border_sm_r_0">
          <div className="d-flex align-items-center">
            {state.companyLogoUrl ? (
              <div className="lg_stack_logo">
                <img src={state.companyLogoUrl} />
              </div>
            ) : state.project?.business && state.project?.business?.name ? (
              initials(state.project?.business.name)
            ) : (
              <div className="lg_stack_logo">
                <img src={Images.Stack} />
              </div>
            )}
            <div>
              <p className="text_black fw-bold fs-5 ms-3 lh-base">
                {state.project?.title || ""}
              </p>
              <p className="font_14 text_primary ms-3 text-start">
                {state.project?.business?.name || ""}
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-4 flex-wrap">
            <div className="d-flex align-items-center flex-wrap">
              <div className="d-flex bg_main p-2 border_r_8px mb-3">
                <span className="text_black fw-bold">
                  {`$${state.project?.hourlyRate || 0}/hr`}
                </span>
                <span className="px-3">|</span>
                <span className="text_black fw-bold">
                  {`${state.project?.duration || 0} Weeks`}
                </span>
              </div>
              <div className="d-flex mb-3 mb-md-0">
                <p className="text_primary ms-3">
                  {moment(state.project.createdAt, "YYYY-MM-DD").fromNow()}
                </p>
                <p className="text_primary ms-1 d-flex align-items-center">
                  <span className="dot" />
                  {`${2} Applicants`}
                </p>
              </div>
            </div>
            {state?.studentProject && (
              <button
                type="button"
                className="btn bg_light_secondary btn_small mob_100 text_secondary"
              >
                <span className="me-2">
                  <i className="fa-sharp fa-regular fa-check" />
                </span>
                Applied
              </button>
            )}
            {!state?.studentProject && (
              <button
                disabled={state.isDisableBtn}
                type="button"
                className={`btn bg_secondary btn_small mob_100 ${
                  state.isDisableBtn ? "bg-rw-disable" : ""
                }`}
                onClick={() =>
                  handleUpdateStudentProjectStatus(
                    StudentProjectIntent.APPLY,
                    state?.project._id
                  )
                }
              >
                Quick Apply
              </button>
            )}
          </div>
          <div className="border-bottom box_shadow w-100 my-4" />
          <p className="font_18 fw-bold text_primary mb-2">Description</p>
          <div
            className="text_primary fs-6"
            dangerouslySetInnerHTML={{
              __html: wrapHTMLContent(state?.project?.description),
            }}
          />
          <p className="font_18 fw-bold text_primary mb-2 mt-4">
            Key Responsibilities
          </p>
          <div
            className="mt-2 text-rw-gray-75"
            dangerouslySetInnerHTML={{
              __html: wrapHTMLContent(state.project?.keyResponsibilities),
            }}
          />
          <p className="font_18 fw-bold text_primary mb-2 mt-4">
            Qualifications
          </p>
          <div
            className="mt-2 text-rw-gray-75"
            dangerouslySetInnerHTML={{
              __html: wrapHTMLContent(state.project?.qualifications),
            }}
          />
          <p className="font_18 fw-bold text_primary mb-2 mt-4">Skills</p>
          <div className="d-flex flex-wrap mt-4">
            {state.project?.skills &&
              state.project.skills.map((skill: string, index: string) => (
                <span key={index} className="tag">
                  {skill}
                </span>
              ))}
          </div>
        </div>
      </div>
      {state?.shouldShowLoginDialog && (
        <LoginDialog
          open={state?.shouldShowLoginDialog}
          onClose={handleCloseShouldShowLoginDialog}
          setUser={setUser}
        />
      )}
      {state.showCompletePersonalityTests && (
        <CompletePersonalityTestsDialog
          projectType={state.project?.type}
          projectId={state.project?._id}
          open={state.showCompletePersonalityTests}
          onClose={toggleCompletePersonalityTests}
        />
      )}
    </div>
  );
}
