import React from "react";
import { wrapNameTwoLetters } from "utils/common";
import { StudentProjectStatus } from "utils/constants";
// import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
// import "react-circular-progressbar/dist/styles.css";

type Props = {
  status?: string;
  studentList: [];
  showSelection?: boolean;
  handleSelect?: (id: any) => void;
  handleOpenProfile?: (id: string, status: string, name: string) => void;
  matchingData: any;
};

const CandidatesList = ({
  status,
  studentList,
  showSelection = false,
  handleSelect = () => {},
  handleOpenProfile = () => {},
  matchingData,
}: Props) => {
  const profileLatters = (
    firstName: string | null,
    lastName: string | null
  ) => (
    <div
      className="profile_image"
      style={{ backgroundColor: "black", width: 32, height: 32 }}
    >
      <p className="profileText">
        {wrapNameTwoLetters(firstName ?? "", lastName ?? "")}
      </p>
    </div>
  );

  const getColor = (value: number) => {
    let returnStr = "";
    if (value > 80) {
      returnStr = "rgba(32,176 ,47)";
    } else if (value > 50) {
      returnStr = "rgba(227,133 ,22)";
    } else {
      returnStr = "rgba(255,0 ,0 )";
    }
    return returnStr;
  };

  return (
    <div>
      <div className="table-responsive all_candidates">
        <table className="table">
          <thead>
            <tr>
              <th scope="col" className="ps-4">
                Name
              </th>
              <th scope="col">
                <span className="font_14 me-2">
                  <i className="fa-regular fa-chevron-down" />
                </span>
                School
              </th>
              {/* <th scope="col">
                <span className="font_14 me-2">
                  <i className="fa-regular fa-chevron-down" />
                </span>
                Resume Match
              </th>
              <th scope="col">
                <span className="font_14 me-2">
                  <i className="fa-regular fa-chevron-down" />
                </span>
                Skill Score
              </th> */}
              <th scope="col">
                <span className="font_14 me-2">
                  <i className="fa-regular fa-chevron-down" />
                </span>
                Personality Type
              </th>
              <th scope="col">
                <span className="font_14 me-2">
                  <i className="fa-regular fa-chevron-down" />
                </span>
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {studentList.map((data: any, index: number) => (
              <tr key={index}>
                <td className="ps-4">
                  <div className="d-flex align-items-center">
                    {showSelection && (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onClick={() => handleSelect(data.student._id)} // data._id
                        value=""
                        id="flexCheckDefault"
                      />
                    )}

                    <div
                      className={`d-flex align-items-center ${
                        status === "IN_ACTIVE" ? "" : "cursor_pointer"
                      } ${showSelection ? "ms-3" : ""}`}
                      onClick={() =>
                        handleOpenProfile(
                          data.student,
                          data.status,
                          `${data?.student?.firstName ?? ""} ${
                            data?.student?.lastName ?? ""
                          }`
                        )
                      }
                    >
                      <div className="candidate_profile">
                        {profileLatters(
                          data?.student?.firstName ?? "",
                          data?.student?.lastName ?? ""
                        )}
                        {data?.suggested && (
                          <span className="suggested_icon">
                            <img
                              src={require("../../../../assets/imgs/suggested_icon.png")}
                            />
                          </span>
                        )}
                        {/* <img
                          src={require("../../../../assets/imgs/profile01.png")}
                        /> */}
                        {/* <span className="suggested_icon">
                              <img
                                src={require("../../../../assets/imgs/suggested_icon.png")}
                              />
                            </span> */}
                      </div>
                      <div>
                        <p
                          className="fw-bold font_14 lh-sm text_secondary"
                          style={{
                            textDecoration: "underline",
                          }}
                        >{`${data?.student?.firstName ?? ""} ${
                          data?.student?.lastName ?? ""
                        }`}</p>
                        {data?.suggested && (
                          <p className="text_secondary fw-bold font_10">
                            SUGGESTED
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="align-middle fw-bold">
                  {data.student?.university}
                </td>
                {/* Resume match skill score */}
                <td className="align-middle fw-bold text_primary text-decoration-underline">
                  {matchingData[`${data.student?._id}`]?.highestPersonalityKey}
                </td>
                <td>
                  {data.status ===
                    StudentProjectStatus.REQUESTED_TO_INTERVIEW && (
                    <div className="bg_light_orange text_orange px-3 py-2 text-center fw-bold d-inline border_r_8px">
                      Requested Interview
                    </div>
                  )}
                  {data.status === StudentProjectStatus.APPLIED && (
                    <div className="bg_light_secondary text_secondary px-3 py-2 text-center fw-bold d-inline border_r_8px">
                      Applied
                    </div>
                  )}
                  {data.status === StudentProjectStatus.IN_PROGRESS && (
                    <div className="bg_light_secondary text_secondary px-3 py-2 text-center fw-bold d-inline border_r_8px">
                      OnGoing
                    </div>
                  )}
                  {data.status === StudentProjectStatus.FINISHED && (
                    <div className="bg_light_secondary text_secondary px-3 py-2 text-center fw-bold d-inline border_r_8px">
                      Finished
                    </div>
                  )}

                  {/* <div className="bg_light_secondary text_secondary px-3 py-2 text-center fw-bold d-inline border_r_8px">
                    {data.status}
                  </div> */}
                </td>
              </tr>
            ))}
            {/* <tr>
              <td className="ps-4">
                <div className="d-flex align-items-center">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <div className="d-flex align-items-center ms-3">
                    <div className="candidate_profile">
                      <img
                        src={require("../../../../assets/imgs/profile01.png")}
                      />
                      {/* <span className="suggested_icon">
                        <img
                          src={require("../../../../assets/imgs/suggested_icon.png")}
                        />
                      </span>
                    </div>
                    <div>
                      <p className="fw-bold font_14 lh-sm">Ford Coleman</p>
                      <p className="text_secondary fw-bold font_10">
                        SUGGESTED
                      </p>
                    </div>
                  </div>
                </div>
              </td>
              <td className="align-middle fw-bold">Georgia Tech</td>
              <td className="align-middle">
                <div className="custom_card">
                  <div className="percent best">
                    <svg>
                      <circle
                        r="70"
                        cx="100"
                        cy="100"
                        fill="transparent"
                        stroke="lightgrey"
                        role="progressbar"
                        style={{ fillRule: "evenodd", fill: "red" }}
                      />
                    </svg>
                    <div className="number">
                      <p className="text_progress">94</p>
                    </div>
                  </div>
                </div>
              </td>
              <td className="text_progress align-middle fw-bold">High-Level</td>
              <td className="align-middle fw-bold text_primary text-decoration-underline">
                Innovator
              </td>
              <td>
                <div className="bg_light_secondary text_secondary px-3 py-2 text-center fw-bold d-inline border_r_8px">
                  Requested Interview
                </div>
              </td>
            </tr>
            <tr>
              <td className="ps-4">
                <div className="d-flex align-items-center">
                  <div className="candidate_profile">
                    <img
                      src={require("../../../../assets/imgs/profile02.png")}
                    />
                  </div>
                  <div>
                    <p className="fw-bold font_14 lh-sm">Cam Holmes</p>
                  </div>
                </div>
              </td>
              <td className="align-middle fw-bold">Georgia Tech</td>
              <td className="align-middle">
                <div className="custom_card">
                  <div className="percent best">
                    <svg>
                      <circle cx="23" cy="23" r="20" />
                      <circle cx="23" cy="23" r="20" />
                    </svg>
                    <div className="number">
                      <p className="text_progress">89</p>
                    </div>
                  </div>
                </div>
              </td>
              <td className="text_orange align-middle fw-bold">Mid-Level</td>
              <td className="align-middle fw-bold text_primary text-decoration-underline">
                Collaborator
              </td>
              <td>
                <div className="bg_secondary text_white px-3 py-2 text-center fw-bold d-inline border_r_8px">
                  Requested Interview
                </div>
              </td>
            </tr>
            <tr>
              <td className="ps-4">
                <div className="d-flex align-items-center">
                  <div className="candidate_profile">
                    <img
                      src={require("../../../../assets/imgs/profile03.png")}
                    />
                  </div>
                  <div>
                    <p className="fw-bold font_14 lh-sm">Bear Jameson</p>
                  </div>
                </div>
              </td>
              <td className="align-middle fw-bold">Georgia Tech</td>
              <td className="align-middle">
                <div className="custom_card">
                  <div className="percent good">
                    <svg>
                      <circle cx="23" cy="23" r="20" />
                      <circle cx="23" cy="23" r="20" />
                    </svg>
                    <div className="number">
                      <p className="text_orange">78</p>
                    </div>
                  </div>
                </div>
              </td>
              <td className="text_orange align-middle fw-bold">Mid-Level</td>
              <td className="align-middle fw-bold text_primary text-decoration-underline">
                Perfectionist
              </td>
              <td>
                <div className="bg_light_secondary text_secondary px-3 py-2 text-center fw-bold d-inline border_r_8px">
                  Applied
                </div>
              </td>
            </tr>
            <tr>
              <td className="ps-4">
                <div className="d-flex align-items-center">
                  <div className="candidate_profile">
                    <img
                      src={require("../../../../assets/imgs/profile04.png")}
                    />
                  </div>
                  <div>
                    <p className="fw-bold font_14 lh-sm">
                      Alex Schmidt-Fellner
                    </p>
                  </div>
                </div>
              </td>
              <td className="align-middle fw-bold">Georgia Tech</td>
              <td className="align-middle">
                <div className="custom_card">
                  <div className="percent good">
                    <svg>
                      <circle cx="23" cy="23" r="20" />
                      <circle cx="23" cy="23" r="20" />
                    </svg>
                    <div className="number">
                      <p className="text_orange">65</p>
                    </div>
                  </div>
                </div>
              </td>
              <td className="text_orange align-middle fw-bold">Mid-Level</td>
              <td className="align-middle fw-bold text_primary text-decoration-underline">
                Achiever
              </td>
              <td>
                <div className="bg_light_orange text_orange px-3 py-2 text-center fw-bold d-inline border_r_8px">
                  Interviewing
                </div>
              </td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CandidatesList;
