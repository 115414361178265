import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  generateResultTypes,
  getChoiceType,
  sortedTallyMap,
  topChoice,
  validateUserPersonalityTest,
} from "./personality-tests/helper";
import Images from "../../assets/images";
import { useMergeState } from "utils/custom-hooks";
import { PersonalityTestColors, PersonalityTests } from "utils/constants";
import { scrollToTop, titleCaseText } from "utils/common";
import { addPersonalityTest } from "api";
import { addResultsToUserState } from "./results/helper";

const TestCard = ({
  icon,
  title,
  isTestCompleted,
  handleStartPersonalityTest,
}: {
  icon: any;
  title: string;
  isTestCompleted: boolean;
  handleStartPersonalityTest: () => void;
}) => {
  return (
    <div className="p-4 border_r_16px border border_gray mb-3">
      <div className="d-flex align-items-center w-100 mb-3">
        <div className="designer_icon">
          <img src={icon} />
        </div>
        <p className="fs-5 fw-bold text_primary ms-3">{title}</p>
      </div>
      <button
        type="button"
        className={`btn ${
          isTestCompleted
            ? "bg_lightgray text_light_primary disabled"
            : "bg_secondary"
        } w-100`}
        onClick={handleStartPersonalityTest}
      >
        {isTestCompleted ? "Completed" : "Start Personality Test"}
      </button>
    </div>
  );
};

export default function GeneralPersonalityList({
  user,
  manuBarExpend,
  manuBarToggle,
}: {
  user: any;
  manuBarExpend: boolean;
  manuBarToggle: () => void;
}) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [types] = useState([
    { type: "Designer", icon: Images.Designer },
    { type: "Developer", icon: Images.Developer },
    { type: "Engineer", icon: Images.Engineer },
    { type: "Business", icon: Images.Business },
  ]);
  const [state, setState] = useMergeState({
    selectedMap: {},
    page: 0,
    choiceTally: {},
    type: "",
    step: 0,
    results: {},
    topChoiceType: {},
    isDisableBtn: false,
  });

  const location = useLocation();
  const isProjectPersonalityTest =
    location.state && location.state?.projectType;
  let projectType = isProjectPersonalityTest
    ? location.state?.projectType
    : null;
  const projectId = isProjectPersonalityTest ? location.state?.projectId : null;
  let project: any = {};
  let testType: string = "";

  if (isProjectPersonalityTest) {
    projectType =
      projectType.charAt(0).toUpperCase() + projectType.slice(1).toLowerCase();

    project = types?.find((item: any) => item.type === projectType);
    testType = project?.type || "";
  }

  const personalityTest: any =
    state.type === "designer"
      ? PersonalityTests.designer
      : state.type === "developer"
      ? PersonalityTests.developer
      : state.type === "engineer"
      ? PersonalityTests.engineer
      : state.type === "business"
      ? PersonalityTests.business
      : undefined;

  const resultTypes: any =
    state.type === "designer"
      ? PersonalityTests.designer.results
      : state.type === "developer"
      ? PersonalityTests.developer.results
      : state.type === "engineer"
      ? PersonalityTests.engineer.results
      : state.type === "business"
      ? PersonalityTests.business.results
      : { undefined };

  const handleCompleteLater = () => {
    navigate("/dashboard");
  };

  const handleStartTest = (type: string) => {
    setState({ type: type.toLowerCase(), step: 1 });
  };

  const handleTestQuestions = () => {
    setState({ choiceTally: generateResultTypes(personalityTest), step: 2 });
  };

  const incPage = async () => {
    if (state.page + 1 < 4) {
      if (Object.keys(state.selectedMap).length >= (state.page + 1) * 3) {
        scrollToTop();
        setState({ page: state.page + 1 });
      } else {
        enqueueSnackbar("Please verify you filled out all previous questions", {
          variant: "error",
        });
      }
    } else if (Object.keys(state.selectedMap).length === 12) {
      const sortedResults = sortedTallyMap(state.choiceTally);
      setState({ isDisableBtn: true });
      try {
        const response = await addPersonalityTest({
          type: state.type,
          results: sortedResults,
        });
        setState({ isDisableBtn: false });
        if (response?.success) {
          addResultsToUserState(sortedResults, state.type.toLowerCase(), user);
          setState({
            step: 3,
            topChoiceType: topChoice(sortedResults),
            results: sortedResults,
          });
        } else {
          enqueueSnackbar(response.message, { variant: "error" });
        }
      } catch (error: any) {
        setState({ isDisableBtn: false });
        enqueueSnackbar(error?.message, { variant: "error" });
      }
    } else {
      enqueueSnackbar("Please verify you filled out all previous questions", {
        variant: "error",
      });
    }
  };

  const decPage = () => {
    if (state.page - 1 >= 0) {
      scrollToTop();
      setState({ page: state.page - 1 });
    } else {
      setState({ step: 1, selectedMap: {} });
    }
  };

  const handleBoxClick = (
    choice: string,
    question: string,
    resultType: string
  ) => {
    if (state.selectedMap[question] === undefined) {
      const updateTally: any = { ...state.choiceTally };
      updateTally[resultType] += 1;

      const updatedSelectedMap = { ...state.selectedMap };
      updatedSelectedMap[question] = choice;

      setState({
        selectedMap: updatedSelectedMap,
        choiceTally: updateTally,
      });
    } else if (
      state.selectedMap[question] !== undefined &&
      state.selectedMap[question] === choice
    ) {
      const updateTally: any = { ...state.choiceTally };
      updateTally[
        getChoiceType(personalityTest, state.page, question, choice)
      ] -= 1;
      const updatedSelectedMap = { ...state.selectedMap };
      delete updatedSelectedMap[question];

      setState({
        selectedMap: updatedSelectedMap,
        choiceTally: updateTally,
      });
    } else if (state.selectedMap[question] !== undefined) {
      const updateTally: any = { ...state.choiceTally };
      updateTally[
        getChoiceType(
          personalityTest,
          state.page,
          question,
          state.selectedMap[question]
        )
      ] -= 1;
      updateTally[resultType] += 1;
      const updatedSelectedMap = { ...state.selectedMap };
      updatedSelectedMap[question] = choice;

      setState({
        selectedMap: updatedSelectedMap,
        choiceTally: updateTally,
      });
    }
  };

  const init = () => {
    if (!isProjectPersonalityTest && validateUserPersonalityTest(user)) {
      navigate("/projects");
    } else if (
      !isProjectPersonalityTest &&
      !validateUserPersonalityTest(user)
    ) {
      setState({ selectedMap: {}, step: 0, page: 0 });
    } else if (isProjectPersonalityTest) {
      const isPersonalityTestPerformed =
        user.personality[projectType.toLowerCase()] !== undefined;
      if (isPersonalityTestPerformed) {
        enqueueSnackbar(
          "Your test has successfully completed. please apply to project now",
          { variant: "success" }
        );
        const projectDetailUrl = `/projects?id=${projectId}&types=${testType.toUpperCase()}&explore=true`;
        navigate(projectDetailUrl);
      }
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <header
        className={`header ${manuBarExpend ? "body_pd" : ""}`}
        id="header"
      >
        <div className="w-25 d-flex align-items-center mob_100">
          <div className="header_toggle" onClick={() => manuBarToggle()}>
            {" "}
            <i
              className={`bx bx-menu ${!manuBarExpend ? "bx-x" : ""}`}
              id="header_toggle"
            />{" "}
          </div>
          <div className="fw-bold fs-5 ms-3 mb-0 d-flex align-items-center">
            <span className="d-block d-md-none me-3">
              <img src={Images.Runaway_logo} />
            </span>
            <span>Personality Assessment</span>
          </div>
        </div>
      </header>
      {state.step === 2 && (
        <div className="custom_progress">
          <div
            className="progress_fill"
            style={{ width: `${state.page * 25}%` }}
          >
            <span className="fs-4 text_secondary">
              <i className="fa-solid fa-check" />
            </span>
            <span className="progress_value">{`${state.page * 25}%`}</span>
          </div>
        </div>
      )}
      <div className="px-0 px-md-3 w-100 mb-5 mb-md-0 mt-0 mt-md-3">
        <div className="p-3 p-md-4 bg_white justify-content-center border border_gray border_r_16px border_sm_r_0 w-100 middle_align">
          {state.step === 0 ? (
            <div className="middle_box small_box">
              <div className="d-flex justify-content-center icon_size_sm text-center mb-4">
                <img src={Images.Brain} />
              </div>
              <p className="fw-bold fs-4 mb-4 text-center lh-base">
                Help us understand your preferred work-style to better match you
                with projects.
              </p>
              {!isProjectPersonalityTest ? (
                types.map((el) => {
                  if (user?.profileTypes.includes(el.type.toUpperCase())) {
                    return (
                      <TestCard
                        icon={el.icon}
                        title={el.type}
                        isTestCompleted={
                          !(
                            user?.personality[el.type.toLowerCase()] ===
                            undefined
                          )
                        }
                        handleStartPersonalityTest={() =>
                          handleStartTest(el.type)
                        }
                      />
                    );
                  }
                })
              ) : (
                <TestCard
                  icon={project?.icon}
                  title={project?.type}
                  isTestCompleted={
                    !(user?.personality[testType.toLowerCase()] === undefined)
                  }
                  handleStartPersonalityTest={() => handleStartTest(testType)}
                />
              )}
              <div className="text-center mt-4 mb-4">
                <button
                  type="button"
                  className="btn bg_light_secondary text_secondary w-75"
                  onClick={handleCompleteLater}
                >
                  Back
                </button>
              </div>
            </div>
          ) : state.step === 1 ? (
            <div className="middle_box small_box">
              <div className="d-flex justify-content-center icon_size_sm text-center mb-4">
                <img src={Images.PersonalityTest} />
              </div>
              <p className="fw-bold fs-4 text-center lh-base">
                {`${titleCaseText(state.type)} Personality Test`}
              </p>
              <p className="text-center mb-4">12 Questions (5-10 min)</p>

              <p className="text-center mb-3">
                This test helps identify your natural work style in a work
                environment.
              </p>

              <p className="text-center mb-3">
                Remember, there are no right or wrong answers, and all
                personality types have their unique strengths.{" "}
              </p>

              <p className="text-center mb-3">
                Always select the option that resonates most with you, not the
                one you think is 'correct'.
              </p>

              <button
                type="button"
                className="btn bg_secondary w-100 mb-3 mt-4"
                onClick={() => handleTestQuestions()}
              >
                Continue
              </button>
              <button
                type="button"
                className="btn bg_light_secondary text_secondary w-100 mb-4"
                onClick={() => setState({ step: 0 })}
              >
                Back
              </button>
            </div>
          ) : state.step === 2 ? (
            <div className="middle_box small_box">
              <p className="bg_light p-3 border_r_8px mb-4 font_14">
                Select the option that resonates most with you, not the one you
                think is 'correct'.
              </p>
              {personalityTest?.questions[state.page].map(
                (questionMap: any) => {
                  return (
                    <div>
                      <p className="fw-bold mb-4 ms-3 mt-3">
                        {questionMap.question}
                      </p>
                      {Object.keys(questionMap.choices).map(
                        (choice: string) => {
                          const isActive =
                            state.selectedMap[questionMap.question] !==
                              undefined &&
                            state.selectedMap[questionMap.question] === choice;
                          return (
                            <div
                              className={`d-flex justify-content-between align-items-center p-3 border_r_8px border border_gray mb-3 cursor-pointer ${
                                isActive ? "active_box" : ""
                              }`}
                              onClick={() => {
                                handleBoxClick(
                                  choice,
                                  questionMap.question,
                                  questionMap.choices[choice]
                                );
                              }}
                            >
                              <p className="text_black font_14 pe-4">
                                {choice}
                              </p>
                              <div className="form-check ">
                                <input
                                  className={`form-check-input cursor-pointer ${
                                    isActive ? "active" : ""
                                  }`}
                                  type="radio"
                                  checked={isActive}
                                />
                                <label className="form-check-label" />
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  );
                }
              )}
              <button
                disabled={state.isDisableBtn}
                type="button"
                className={`btn w-100 mb-3 mt-4 ${
                  state.isDisableBtn ? "bg-rw-disable" : "bg_secondary"
                }`}
                onClick={incPage}
              >
                {state.page === 3 ? "Complete" : "Continue"}
              </button>
              <button
                type="button"
                className="btn bg_light_secondary text_secondary w-100 mb-4"
                onClick={decPage}
              >
                Back
              </button>
            </div>
          ) : (
            <div className="middle_box small_box">
              <p className="fw-bold fs-4 text-center mb-4">
                Personality Results
              </p>
              <div className="custom_box border_sm_r_0">
                <div className="d-flex align-items-center mb-4 icon_size_sm">
                  <img src={personalityTest.icon} />
                  <p className="fw-bold text_black fs-5 ms-4">
                    {titleCaseText(state.type)}
                  </p>
                </div>
                <div className=" border-bottom border_gray my-4" />
                <div className="d-flex icon_size_lg">
                  <img src={Images.Innovator} />
                  <div className="ms-4">
                    <p className="fs-4 fw-bold text_black">
                      {state.topChoiceType[0]}
                    </p>
                    <p>{resultTypes[state.topChoiceType[0]]?.text}</p>
                  </div>
                </div>
                <div className=" border-bottom border_gray mt-4 mb-5" />
                {Object.keys(state.results).map(
                  (key: string, index: number) => {
                    return (
                      <div className="d-flex align-items-center mb-3 flex-wrap">
                        <p className="font_14 text_black w_35 w_sm_100 me-3">
                          {key}
                        </p>
                        <div className="progress w-50 w_sm_100">
                          <div
                            className={`progress-bar ${PersonalityTestColors[index]}`}
                            role="progressbar"
                            style={{ width: `${state.results[key]}%` }}
                          >
                            {`${state.results[key]}%`}
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
              <div className="text-center mt-4 mb-4">
                <button
                  type="button"
                  className="btn bg_secondary w-100"
                  onClick={() => init()}
                >
                  Continue
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
