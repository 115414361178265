import { updateProfile } from "api";
import { enqueueSnackbar } from "notistack";
import { ChangeEvent, useState } from "react";

interface BusinessNotificationDetailsProps {
  user: any;
}

export const BusinessNotificationDetails: React.FC<
  BusinessNotificationDetailsProps
> = ({ user }) => {
  const [newInvoice, setNewInvoice] = useState(
    user?.notificationSettings?.email?.newInvoices ?? false
  );
  const [reminder, setReminder] = useState(
    user?.notificationSettings?.email?.invoiceReminder ?? false
  );
  const [newCandidate, setNewCandidate] = useState(
    user?.notificationSettings?.email?.newCandidate ?? false
  );

  const buildNotificationObject = (key: string, value: boolean) => {
    return {
      ...user.notificationSettings,
      email: {
        newInvoices: newInvoice,
        invoiceReminder: reminder,
        newCandidate,
        [key]: value,
      },
    };
  };

  const handleResponse = (success: boolean) => {
    if (success) {
      enqueueSnackbar("Notification settings updated successfully.", {
        variant: "success",
      });
    }
  };

  const onToggleNewInvoices = async (event: ChangeEvent<HTMLInputElement>) => {
    setNewInvoice(event.target.checked);

    const response = await updateProfile({
      notificationSettings: buildNotificationObject(
        "newInvoices",
        event.target.checked
      ),
    });

    handleResponse(response.success);
  };

  const onToggleReminder = async (event: ChangeEvent<HTMLInputElement>) => {
    setReminder(event.target.checked);

    const response = await updateProfile({
      notificationSettings: buildNotificationObject(
        "invoiceReminder",
        event.target.checked
      ),
    });

    handleResponse(response.success);
  };

  const onToggleNewApply = async (event: ChangeEvent<HTMLInputElement>) => {
    setNewCandidate(event.target.checked);

    const response = await updateProfile({
      notificationSettings: buildNotificationObject(
        "newCandidate",
        event.target.checked
      ),
    });

    handleResponse(response.success);
  };

  return (
    <div className="bg-white p-4 border border-gray-200 rounded-2xl w-full flex flex-col gap-3">
      <p className="fw-bold text-gray-800 fs-5">Email Notifications</p>
      <div className="d-flex justify-content-between align-items-center">
        <p>New Invoices</p>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            name="newInvoices"
            checked={newInvoice}
            onChange={onToggleNewInvoices}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <p>Invoice Reminder</p>
          <p className="font_12 text_light_primary">
            2 days before invoice is autopaid
          </p>
        </div>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            name="invoiceReminder"
            checked={reminder}
            onChange={onToggleReminder}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <p>New Applicants</p>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            name="newCandidate"
            checked={newCandidate}
            onChange={onToggleNewApply}
          />
        </div>
      </div>
    </div>
  );
};
