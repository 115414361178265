import { useContext } from "react";
import { FormPhoneInput } from "runway-kit/FormPhoneInput";
import { FormTextInput } from "runway-kit/FormTextInput";
import { BusinessSettingsContext } from "../BusinessSettingsContext";

export const BusinessProfileDetails: React.FC = () => {
  const { isEditing } = useContext(BusinessSettingsContext);
  return (
    <div className="bg-white p-4 border border-gray-200 rounded-2xl w-full flex flex-col gap-3">
      <p className="fw-bold text-gray-800 fs-5">Profile Details</p>
      <FormTextInput name="email" label="Email Address" disabled={!isEditing} />
      <div className="flex gap-3 flex-col md:flex-row">
        <FormTextInput
          name="firstName"
          label="First Name"
          disabled={!isEditing}
        />
        <FormTextInput
          name="lastName"
          label="Last Name"
          disabled={!isEditing}
        />
      </div>
      <FormPhoneInput name="phone" label="Phone Number" disabled={!isEditing} />
    </div>
  );
};
