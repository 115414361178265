import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useSnackbar } from "notistack";
import { useCallback, useContext, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { toBase64 } from "utils/common";
import { BusinessSettingsContext } from "../BusinessSettingsContext";
import { Button } from "runway-kit/Button";

interface CompanyLogoUploadProps {
  profileImageUrl: string;
}

export const CompanyLogoUpload: React.FC<CompanyLogoUploadProps> = ({
  profileImageUrl,
}) => {
  const companyLogoRef = useRef<any>();
  const { enqueueSnackbar } = useSnackbar();
  const { setIsEditing, companyLogoBase64, setCompanyLogoBase64 } = useContext(
    BusinessSettingsContext
  );

  const handleCompanyLogo = async (file: any) => {
    setIsEditing(true);
    try {
      if (!file) return;
      if (file?.name.includes("png") || file?.name.includes("jpg")) {
        const base64 = await toBase64(file);
        setCompanyLogoBase64(base64);
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleCompanyLogoRef = () => {
    companyLogoRef?.current?.click();
  };

  const onDrop = useCallback((acceptedFiles: any) => {
    try {
      handleCompanyLogo(acceptedFiles[0]);
    } catch (err: any) {
      enqueueSnackbar(err?.message, { variant: "error" });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      image: [".jpg", ".png"],
    },
  });

  if (profileImageUrl || companyLogoBase64)
    return (
      <div className="flex gap-3  flex-col">
        <div
          className="bg-cover w-[5rem] h-[5rem] border border-gray-100 rounded-lg bg-center"
          style={{
            backgroundImage: `url(${companyLogoBase64 || profileImageUrl})`,
          }}
        />
        <Button size="md" variant="secondary" onClick={handleCompanyLogoRef}>
          <p className="text-sm flex items-center gap-2">
            Change Logo <EditOutlinedIcon fontSize="small" />
          </p>
        </Button>
        <input
          type="file"
          name="companyLogo"
          id="file"
          accept="image/png, image/jpg"
          ref={companyLogoRef}
          disabled={false}
          onChange={(event: any) => handleCompanyLogo(event?.target?.files[0])}
        />
      </div>
    );

  return (
    <div
      className={`border-1 border-dashed rounded-lg py-8 flex items-center justify-center text-gray-400  cursor-pointer active:bg-gray-200  ${
        isDragActive
          ? "border-rw-blue hover:bg-rw-blue-lightest bg-rw-blue-lightest text-gray-500"
          : "border-gray-400 hover:bg-gray-100 hover:text-gray-500"
      }`}
      {...getRootProps()}
    >
      <div className="flex flex-col items-center gap-1">
        <i className="fa-light fa-cloud-arrow-up" />
        <p className="text-sm font-bold">Upload JPEG or PNG File</p>
        <p className="text-xs">
          Drop your logo here, or{" "}
          <span className="text-rw-blue text-opacity-80">browse</span>.
        </p>
      </div>
      <input {...getInputProps({ onClick: handleCompanyLogoRef })} />
      <input
        type="file"
        name="companyLogo"
        id="file"
        accept="image/png, image/jpg"
        ref={companyLogoRef}
        disabled={false}
        onChange={(event: any) => handleCompanyLogo(event?.target?.files[0])}
      />
    </div>
  );
};
