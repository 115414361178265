import { useEffect } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";

// layouts
import DefaultLayout from "layouts/default";

// containers

// auth
import SignInContainer from "containers/auth/signin";
import SignUpContainer from "containers/auth/signup";
import EmailVerification from "containers/email-verification";
import StudentSignUpContainer from "containers/auth/signup/student";
import BusinessSignUpContainer from "containers/auth/signup/business";
import BusinessMemberSignUpContainer from "containers/auth/signup/business/member";
import ResetPasswordContainer from "containers/auth/reset-password";
import MerchantResetPasswordVerifyLinkExpiryContainer from "containers/auth/reset-password/verify-link-expiry";
import StripeConnectAccountContainer from "containers/auth/stripe-connect-account";

// dashboard
import DashboardContainer from "containers/dashboard";

// projects
import ProjectsContainer from "containers/projects";
import ProjectsListCareerFairContainer from "containers/projects/career-fair";
import CreateProjectsContainer from "containers/projects/create";
import ProjectsDetailsContainer from "containers/projects/details";
// new design
import MyProjects from "containers/MyProjects";

// personality tests
import StartPersonalityTest from "containers/start-personality-test/index";
import PersonalityTest from "containers/start-personality-test/personality-tests/index";
import ResultsContainer from "containers/start-personality-test/results/index";

import SkillAssessment from "containers/skills-assessment";

// settings
import SettingsContainer from "containers/settings";

// confirm-payment
import ConfirmPaymentContainer from "containers/confirm-payment";

// community
import CommunityContainer from "containers/community";
import BlogsContainer from "containers/community/blogs";

// admin
import AdminContainer from "containers/admin";

// components
import AppLoader from "components/AppLoader";
import PageNotFound from "components/PageNotFound";
import ProtectedRoute from "components/ProtectedRoute";

// utils
import { useMergeState, useWindowSize } from "utils/custom-hooks";

// api
import { getCurrentUser, getSignedUrlOfS3 } from "api";
import { RUNWAY_TOKEN } from "utils/constants";
import ProjectDetails from "containers/MyProjects/details";

export default function RoutesContainer() {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const [state, setState] = useMergeState({
    isLoggedIn: false,
    user: {},
    manuBarExpend: true,
  });

  const currentWidth = useWindowSize().width;
  const isAppLoading = localStorage.getItem(RUNWAY_TOKEN) && !state?.isLoggedIn;

  const setUser = (user: any) => {
    setState({ isLoggedIn: true, user });
  };

  const onLogout = () => {
    localStorage.removeItem(RUNWAY_TOKEN);
    amplitude.reset();
    window.location.href = "/";
  };

  const manuBarToogle = () => {
    setState({ manuBarExpend: !state.manuBarExpend });
  };

  const init = async () => {
    let userInfo: any = {};
    try {
      if (!localStorage.getItem(RUNWAY_TOKEN)) {
        // window.location.href = "/";
        return;
      }

      const response = await getCurrentUser();

      if (response?.success) {
        userInfo = response?.data?.user;
        if (userInfo?.avatar && userInfo?.avatar?.key) {
          const signedUrlRes = await getSignedUrlOfS3(userInfo?.avatar?.key);
          if (signedUrlRes?.success) {
            userInfo.avatar = {
              ...userInfo.avatar,
              url: signedUrlRes.data.url,
            };
          }
        }
        setUser(userInfo);
      }
    } catch (error: any) {
      localStorage.removeItem(RUNWAY_TOKEN);

      window.location.href = "/";
      // setUser(userInfo);

      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (currentWidth < 800) {
      setState({ manuBarExpend: false });
    }
  }, [location.pathname, useWindowSize().width]);

  useEffect(() => {
    init();
  }, []);

  return (
    <div
      className={`flex flex-col flex-grow ${
        state.isLoggedIn ? "body_padding_left" : ""
      }`}
      style={{
        paddingLeft: state.isLoggedIn && state?.manuBarExpend ? 156 : 0,
      }}
    >
      {isAppLoading ? (
        <div className="w-full h-screen flex justify-center mt-10">
          <AppLoader isLoading={isAppLoading} />
        </div>
      ) : (
        <Routes>
          <Route
            path="/"
            element={
              <DefaultLayout
                isLoggedIn={state?.isLoggedIn}
                user={state?.user}
                onLogout={onLogout}
                manuBarExpend={state?.manuBarExpend}
                manuBarToogle={manuBarToogle}
              />
            }
          >
            <Route
              path="signin"
              element={
                <SignInContainer
                  setUser={setUser}
                  loggedIn={state?.isLoggedIn}
                />
              }
            />

            <Route path="signup">
              <Route
                path=""
                element={
                  <SignUpContainer
                    setUser={setUser}
                    loggedIn={state?.isLoggedIn}
                  />
                }
              />
              <Route
                path="student"
                element={<StudentSignUpContainer setUser={setUser} />}
              />
              <Route path="business">
                <Route
                  path=""
                  element={<BusinessSignUpContainer setUser={setUser} />}
                />
                <Route
                  path="member"
                  element={<BusinessMemberSignUpContainer setUser={setUser} />}
                />
              </Route>
            </Route>

            <Route
              path="email-verification"
              element={
                <EmailVerification
                  setUser={setUser}
                  loggedIn={state?.isLoggedIn}
                />
              }
            />

            <Route path="reset-password">
              <Route path="" element={<ResetPasswordContainer />} />
              <Route
                path="verify-link-expiry/:token"
                element={<MerchantResetPasswordVerifyLinkExpiryContainer />}
              />
            </Route>

            <Route path="projects">
              <Route
                path=""
                element={
                  // <MyProjects
                  //   user={state?.user}
                  //   manuBarToogle={manuBarToogle}
                  // />
                  <ProjectsContainer
                    manuBarExpend={state.manuBarExpend}
                    isLoggedIn={state?.isLoggedIn}
                    user={state?.user}
                    setUser={setUser}
                    manuBarToogle={manuBarToogle}
                  />
                }
              />

              <Route
                path="career-fair"
                element={
                  <ProjectsListCareerFairContainer
                    isLoggedIn={state?.isLoggedIn}
                    user={state?.user}
                  />
                }
              />

              <Route
                path="create"
                element={
                  <CreateProjectsContainer
                    user={state?.user}
                    manuBarExpend={state.manuBarExpend}
                    manuBarToogle={manuBarToogle}
                  />
                }
              />

              {/* <Route
                path="details"
                element={
                  // <ProjectDetails
                  //   user={state?.user}
                  //   manuBarExpend={state.manuBarExpend}
                  //   manuBarToogle={manuBarToogle}
                  // />
                  <ProjectsDetailsContainer
                    isLoggedIn={state?.isLoggedIn}
                    manuBarExpend={state.manuBarExpend}
                    manuBarToogle={manuBarToogle}
                    user={state?.user}
                    setUser={setUser}
                  />
                }
              /> */}
            </Route>
            {/* <Route path="my-projects">
              <Route
                path=""
                element={
                  <MyProjects
                    user={state?.user}
                    manuBarToogle={manuBarToogle}
                  />
                }
              />
              <Route
                path="details"
                element={
                  <ProjectDetails
                    user={state?.user}
                    manuBarToogle={manuBarToogle}
                  />
                }
              />
            </Route> */}

            <Route element={<ProtectedRoute isLoggedIn={state?.isLoggedIn} />}>
              <Route path="personality-tests">
                <Route
                  path=""
                  element={
                    <StartPersonalityTest
                      user={state?.user}
                      manuBarExpend={state.manuBarExpend}
                      manuBarToggle={manuBarToogle}
                    />
                  }
                />
                <Route
                  path="persona"
                  element={<PersonalityTest user={state?.user} />}
                />
                <Route
                  path="results"
                  element={<ResultsContainer user={state?.user} />}
                />
              </Route>

              <Route path="skills-assessment">
                <Route
                  path=""
                  element={
                    <SkillAssessment
                      user={state?.user}
                      manuBarExpend={state.manuBarExpend}
                      setUser={setUser}
                      manuBarToggle={manuBarToogle}
                    />
                  }
                />
              </Route>

              <Route
                path="dashboard"
                element={
                  <DashboardContainer
                    user={state?.user}
                    manuBarExpend={state.manuBarExpend}
                    manuBarToogle={manuBarToogle}
                    onLogout={onLogout}
                  />
                }
              />

              <Route
                path="projects/details"
                element={
                  <ProjectsDetailsContainer
                    isLoggedIn={state?.isLoggedIn}
                    manuBarExpend={state.manuBarExpend}
                    manuBarToogle={manuBarToogle}
                    user={state?.user}
                    setUser={setUser}
                  />
                }
              />

              <Route path="community">
                <Route
                  path=""
                  element={<CommunityContainer user={state?.user} />}
                />
                <Route
                  path="blogs/:blogKey"
                  element={<BlogsContainer user={state?.user} />}
                />
              </Route>

              <Route
                path="settings"
                element={
                  <SettingsContainer
                    user={state?.user}
                    manuBarExpend={state.manuBarExpend}
                    setUser={setUser}
                    manuBarToogle={manuBarToogle}
                    onLogout={onLogout}
                  />
                }
              />

              <Route
                path="connect-account"
                element={<StripeConnectAccountContainer />}
              />

              <Route
                path="confirm-payment"
                element={<ConfirmPaymentContainer />}
              />

              {state?.user?.isSuperAdmin && (
                <Route
                  path="admin"
                  element={<AdminContainer setUser={setUser} />}
                />
              )}
            </Route>

            <Route
              path="/"
              element={
                <Navigate to={state?.isLoggedIn ? "/projects" : "/signin"} />
              }
            />
          </Route>

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      )}
    </div>
  );
}
