import { BusinessProfileDetails } from "./BusinessProfileDetails";
import { BusinessCompanyDetails } from "./BusinessCompanyDetails";

interface BusinessProfilePaneProps {
  profileImageUrl: string;
}

export const BusinessProfilePane: React.FC<BusinessProfilePaneProps> = ({
  profileImageUrl,
}) => {
  return (
    <div className="flex gap-4 mt-3 w-full flex-col lg:flex-row">
      <BusinessProfileDetails />
      <BusinessCompanyDetails profileImageUrl={profileImageUrl} />
    </div>
  );
};
