import React from "react";
import { wrapNameTwoLetters } from "utils/common";

type Props = {
  firstName: string;
  lastName: string;
  university: string;
  handlePress: () => void;
  buttonTittle: String;
  showBackButton: boolean;
  backPress: () => void;
  disablePrimaryButton?: boolean;
};

const ProfileBar = ({
  firstName,
  lastName,
  university,
  handlePress,
  buttonTittle,
  showBackButton,
  backPress,
  disablePrimaryButton,
}: Props) => {
  const profileLatters = (
    <div className="profile_image" style={{ width: 70, height: 70 }}>
      <p className="profileText" style={{ fontSize: 28 }}>
        {wrapNameTwoLetters(firstName, lastName)}
      </p>
    </div>
  );

  return (
    <div className="bg_light p-3 p-md-4 w-100 overflow-hidden">
      <div className="p-3 p-md-4 bg_white justify-content-center border border_gray border_r_16px">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            {profileLatters}
            <div className="ms-3">
              <p className="fs-4 fw-bold mb-0">{`${firstName} ${lastName}`}</p>
              <p className="fs-6 text_primary mb-0">{university}</p>
            </div>
          </div>
          <div className="mt-2 mt-md-0 w_sm_100">
            {showBackButton && (
              <button
                type="button"
                style={{ marginRight: 10 }}
                // className="btn btn_small bg_secondary w_sm_100 my-2"
                className="btn btn_small bg_light_secondary text_secondary"
                onClick={() => backPress()}
              >
                Back
              </button>
            )}

            <button
              type="button"
              disabled={disablePrimaryButton}
              className={
                disablePrimaryButton
                  ? "btn btn_small bg_light_secondary  bg-rw-disable"
                  : "btn btn_small bg_secondary w_sm_100 my-2"
              }
              style={{
                cursor: disablePrimaryButton ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                handlePress();
              }}
            >
              {buttonTittle}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileBar;
