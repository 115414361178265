import { useContext } from "react";
import { CompanyLogoUpload } from "./CompanyLogoUpload";
import { FormTextInput } from "runway-kit/FormTextInput";
import { BusinessSettingsContext } from "../BusinessSettingsContext";

interface BusinessCompanyDetailsProps {
  profileImageUrl: string;
}

export const BusinessCompanyDetails: React.FC<BusinessCompanyDetailsProps> = ({
  profileImageUrl,
}) => {
  const { isEditing } = useContext(BusinessSettingsContext);

  return (
    <div className="bg-white p-4 border border-gray-200 rounded-2xl w-full flex flex-col gap-3">
      <p className="fw-bold text-gray-800 fs-5">Company Details</p>
      <FormTextInput
        name="companyName"
        label="Company Name"
        disabled={!isEditing}
      />
      <label className="text-sm">Company Logo</label>
      <CompanyLogoUpload profileImageUrl={profileImageUrl} />
    </div>
  );
};
